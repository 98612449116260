import { useCallback, useMemo, useRef, useState } from 'react';
import { IOpenActivityDialog } from '../../../../dialog.service';
import { FlexPeriod } from '../../../../services/flex-period.service';
import { SchoolActivity } from '../../../../services/school-activity.service';
import { isBeforeOrSameDate } from '../../../lib/util';

declare const window: Window & typeof globalThis & { dialogService: { openActivityDialog: (_: IOpenActivityDialog) => void } };

export function useFlexStats(flexPeriod: FlexPeriod) {
	const findNextValidDay = (date, increment) => {
		const nextDate = new Date(date.getTime()); // Create a new Date object
		nextDate.setHours(12, 0, 0, 0);
		let attempts = 0;
		const maxAttempts = 7;

		let dayOfWeek = nextDate.getDay();
		do {
			if (attempts > 0 || increment !== 0) {
				nextDate.setDate(nextDate.getDate() + increment);
			}

			dayOfWeek = nextDate.getDay();

			attempts++;
			if (attempts > maxAttempts) {
				throw new Error('No valid day found in FlexPeriod schedules');
			}
		} while (!flexPeriod.schedules?.some((schedule) => schedule.days_of_week.includes(dayOfWeek)));

		return nextDate;
	};

	const initialDateRef = useRef(findNextValidDay(new Date(new Date().setDate(new Date().getDate() - 1)), 1));

	const [currentDate, setCurrentDate] = useState(() =>
		findNextValidDay(
			// Yesterday
			new Date(new Date().setDate(new Date().getDate() - 1)),
			1
		)
	);

	const isStartingDay = useMemo(() => {
		return initialDateRef.current.toDateString() === currentDate.toDateString();
	}, [currentDate]);

	const { startTime, endTime, atStartDate } = useMemo(() => {
		const baseDate = new Date(currentDate);
		baseDate.setHours(12, 0, 0, 0);
		const startTime = new Date(baseDate);
		const endTime = new Date(baseDate);

		const schedule = flexPeriod.schedules?.find((s) => s.days_of_week.includes(baseDate.getDay()));
		if (schedule) {
			startTime.setHours(schedule.start_hour, schedule.start_minute, 0, 0);
			endTime.setHours(schedule.end_hour, schedule.end_minute, 0, 0);
		}

		let atStartDate = false;
		if (isBeforeOrSameDate(startTime, new Date(flexPeriod.created_at))) {
			atStartDate = true;
		}

		return { startTime, endTime, atStartDate };
	}, [currentDate, flexPeriod.schedules, flexPeriod.created_at]);

	const nextDay = useCallback(() => {
		setCurrentDate((prev) => findNextValidDay(prev, 1));
	}, []);

	const prevDay = useCallback(() => {
		setCurrentDate((prev) => findNextValidDay(prev, -1));
	}, []);

	const [refreshKey, setRefreshKey] = useState(0);

	const openActivityDialog = (activity: SchoolActivity) => {
		const dialogService = window?.dialogService;
		if (dialogService) {
			dialogService?.openActivityDialog({
				activity: activity,
				day: currentDate,
				updateCallback: () => {
					setRefreshKey((oldKey) => oldKey + 1);
				},
			});
		}
	};

	return { startTime, endTime, atStartDate, nextDay, prevDay, openActivityDialog, refreshKey, isStartingDay };
}
