import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BackpackIconsModule } from 'app/backpack-icons/backpack-icons.module';
import { ResolveAssetPipe } from 'app/resolve-asset.pipe';
import { MaterialModule } from 'app/shared/material/material.module';
import { ButtonComponent } from './button/button.component';
import { BetaPillComponent } from './sidenav/beta-pill.component';
import { SidenavFloatingMenuComponent } from './sidenav/sidenav-floating-menu.component';
import { SidenavMenuComponent } from './sidenav/sidenav-menu.component';
import { SidenavTabComponent } from './sidenav/sidenav-tab.component';
import { TagComponent } from './tag/tag.component';
import { TooltipComponent } from './tooltip/tooltip.component';

@NgModule({
	imports: [CommonModule, MaterialModule, RouterModule, BackpackIconsModule],
	declarations: [
		// Pipes
		ResolveAssetPipe,

		// Components
		ButtonComponent,
		BetaPillComponent,
		SidenavFloatingMenuComponent,
		SidenavMenuComponent,
		SidenavTabComponent,
		TagComponent,
		TooltipComponent,
	],
	exports: [
		// Pipes
		ResolveAssetPipe,

		// Components
		ButtonComponent,
		BetaPillComponent,
		SidenavFloatingMenuComponent,
		SidenavMenuComponent,
		SidenavTabComponent,
		TagComponent,
		TooltipComponent,
		BackpackIconsModule,
	],
})
export class BackpackModule {}
