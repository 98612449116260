import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { pickTruthy } from 'Util';
import { FeatureFlagService, FLAGS } from './feature-flag.service';
import { PollingServiceNew } from './polling-service-new';
import { PollingServiceOld } from './polling-service-old';
import { PollingEvent } from './polling-service.types';

export * from './polling-service.types';

interface PollingServiceImpl {
	/**
	 * An observable that emits its current value on subscribe and whenever the coonection state changes.
	 */
	isConnected$: Observable<boolean>;

	refreshHeartbeatTimer(): void;
	listen(filterString?: string): Observable<PollingEvent>;
	listenOnCurrentSchool(filterString?: string): Observable<PollingEvent>;
	sendMessage(action: string, data?: string): void;
}

@Injectable({
	providedIn: 'root',
})
export class PollingService {
	private _impl$ = new BehaviorSubject<PollingServiceImpl | null>(null);
	private impl$ = this._impl$.pipe(pickTruthy());
	isConnected$ = new BehaviorSubject<boolean>(true);

	isConnectedSubscription?: Subscription;

	constructor(featureFlagService: FeatureFlagService, pollingServiceOld: PollingServiceOld, pollingServiceNew: PollingServiceNew) {
		featureFlagService
			.isFeatureEnabledV2$(FLAGS.UseNewWebsocket)
			.pipe(
				tap((useNewWebsocket) => {
					if (!useNewWebsocket) {
						pollingServiceOld.init();
					}
				}),
				map((useNewWebsocket) => {
					return useNewWebsocket ? pollingServiceNew : pollingServiceOld;
				})
			)
			.subscribe(this._impl$);

		this.impl$.subscribe((impl) => {
			if (this.isConnectedSubscription) {
				this.isConnectedSubscription.unsubscribe();
			}
			this.isConnectedSubscription = impl.isConnected$.subscribe(this.isConnected$);
		});
	}

	refreshHeartbeatTimer(): void {
		this._impl$.getValue()?.refreshHeartbeatTimer();
	}

	listen(filterString?: string): Observable<PollingEvent> {
		return this.impl$.pipe(switchMap((impl) => impl.listen(filterString)));
	}

	listenOnCurrentSchool(filterString?: string): Observable<PollingEvent> {
		return this.impl$.pipe(switchMap((impl) => impl.listenOnCurrentSchool(filterString)));
	}

	sendMessage(action: string, data?: string): void {
		this._impl$.getValue()?.sendMessage(action, data);
	}

	restartOnConnected() {
		return <T>(source: Observable<T>): Observable<T> =>
			this.isConnected$.pipe(
				filter(Boolean),
				switchMap(() => source)
			);
	}
}
