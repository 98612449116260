import { Action, createReducer, on } from '@ngrx/store';
import * as userActions from '../actions';
import { UserState } from '../states';

const userInitialState: UserState = {
	user: null,
	loading: false,
	loaded: false,
	currentUpdatedUser: null,
};

const reducer = createReducer(
	userInitialState,
	on(userActions.getUser, (state) => ({ ...state, user: null, loading: true, loaded: false })),
	on(userActions.getUserSuccess, userActions.updateUserSuccess, userActions.updateUserPictureSuccess, (state, { user }) => {
		return {
			...state,
			user,
			loading: false,
			loaded: true,
			currentUpdatedUser: user,
		};
	}),
	on(userActions.clearUser, (state) => ({ ...state, user: null, loaded: true, loading: false }))
);

export function userReducer(state: UserState | undefined, action: Action) {
	return reducer(state, action);
}
