import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { AuthenticationService } from 'app/services/authentication.service';
import * as moment from 'moment';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, takeUntil, withLatestFrom } from 'rxjs/operators';
import { DarkThemeSwitch } from '../dark-theme-switch';
import { DeviceDetection } from '../device-detection.helper';
import { School } from '../models/School';
import { User } from '../models/User';
import { AppState } from '../ngrx/app-state/app-state';
import { updateIntrosSeenCombinedTeacherAdminViewLocalReset } from '../ngrx/intros';
import { FeatureFlagService, FLAGS } from '../services/feature-flag.service';
import { HttpService } from '../services/http-service';
import { IDCardService } from '../services/IDCardService';
import { KioskModeService } from '../services/kiosk-mode.service';
import { LocalizejsService } from '../services/localizejs.service';
import { SideNavService } from '../services/side-nav.service';
import { UserService } from '../services/user.service';

export interface Setting {
	hidden: boolean;
	background: string;
	icon: string;
	action: SettingAction;
	title: string;
	tooltip?: string;
	isNew?: boolean;
	tag?: string;
	toolTipDescription?: string;
}

export enum SettingAction {
	About = 'about',
	Appearance = 'appearance',
	Bug = 'bug',
	Classes = 'classes',
	GetStarted = 'getStarted',
	Favorite = 'favorite',
	Groups = 'groups',
	IdCard = 'id-card',
	Language = 'language',
	MyPin = 'myPin',
	Notifications = 'notifications',
	Privacy = 'privacy',
	Profile = 'profile',
	Refer = 'refer',
	ReferSchool = 'referSchool',
	SignOut = 'signout',
	Support = 'support',
	SwagShop = 'swagShop',
	Switch = 'switch',
	Team = 'team',
	Term = 'term',
	WishList = 'wishlist',
}

export type DialogData = {
	trigger: ElementRef;
	isSwitch: boolean;
	settings?: DialogDataSetting[];
};

// todo from typescript 4.1
//type hex = `#${string}`;
type hex = string;

type DialogDataSetting = {
	hidden: boolean;
	background: hex;
	icon: string;
	action: SettingAction;
	title: string;
	isNew: boolean;
};

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
	@Input() dataSideNav: any = null;
	@Input() isParent = false;

	settingsInitialized = false;

	private targetElementRef: ElementRef;
	settings: Setting[] = [];
	user: User;
	private isStaff: boolean;
	private introsData: any;

	isSwitch: boolean;
	combinedTeacherAdminEnabledNoNUX = this.featureFlagService.isFeatureEnabledV2(FLAGS.CombinedTeacherAdminNoNUX);

	hoveredMasterOption: boolean;
	hoveredSignout: boolean;
	teacherPin$: Observable<string | number> = this.userService.getUserPin().pipe(shareReplay({ refCount: true, bufferSize: 1 }));
	private isKioskModeSettingsPage = false;
	private adjustForScroll = false;
	isKioskMode = false;
	isMobile = false;
	showNotificationBadge = false;
	private currentSchool: School;

	// for use in the template
	settingAction = SettingAction;
	private destroy$: Subject<any> = new Subject<any>();

	// #combinedTeacherAdminNux
	customNuxTagStyle = {
		fontColor: 'tw-text-gray-500',
		backgroundColor: 'tw-bg-gray-100',
	};

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData,
		@Optional() public dialogRef: MatDialogRef<SettingsComponent>,
		private sideNavService: SideNavService,
		public darkTheme: DarkThemeSwitch,
		public kioskMode: KioskModeService,
		private router: Router,
		private pwaStorage: LocalStorage,
		private userService: UserService,
		private localize: LocalizejsService,
		private idCardService: IDCardService,
		private httpService: HttpService,
		private authenticationService: AuthenticationService,
		private featureFlagService: FeatureFlagService,
		private store: Store<AppState>
	) {
		if (data && data['adjustForScroll']) {
			this.adjustForScroll = data['adjustForScroll'];
		}
	}

	ngOnInit(): void {
		this.isKioskMode = this.kioskMode.isKioskMode();
		this.isMobile = DeviceDetection.isMobile();

		this.httpService.currentSchool$.subscribe((currentSchool) => {
			this.currentSchool = currentSchool;
		});

		this.router.events.subscribe((value) => {
			if (value instanceof NavigationEnd) {
				if (value.url === `/main/kioskMode/settings`) {
					this.isKioskModeSettingsPage = true;
				}
			}
		});

		const user$ = this.userService.userJSON$.pipe(
			filter((user) => !!user),
			takeUntil(this.destroy$)
		);

		const intro$ = this.userService.introsData$.pipe(takeUntil(this.destroy$));

		intro$.pipe(withLatestFrom(user$)).subscribe({
			next: ([intros, user]) => {
				this.user = User.fromJSON(user);
				this.showNotificationBadge = this.user && moment(this.user.first_login).add(30, 'days').isSameOrBefore(moment());
				this.isStaff = this.user.isTeacher() || this.user.isAssistant();
				this.introsData = intros;
				// data.settings has prority
				if (!this?.data?.settings) {
					this.settings = [];
					this.initializeSettings();
				}
			},
		});

		if (this.data) {
			this.targetElementRef = this.data['trigger'];
			this.isSwitch = this.data['isSwitch'] && !this.kioskMode.getCurrentRoom().value;
			// overwrite existent settings
			if (this.data.settings) {
				this.settings = this.data.settings;
			}
		}

		this.sideNavService.sideNavData.pipe(takeUntil(this.destroy$)).subscribe((sideNavData: DialogData) => {
			if (sideNavData) {
				this.targetElementRef = sideNavData['trigger'];
				this.isSwitch = false;
			}
		});

		this.sideNavService.toggle.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.settings = [];
			this.initializeSettings();
		});

		this.updateDialogPosition();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getIdCardEnabled(): Observable<boolean> {
		if (!this.userService.getFeatureFlagDigitalID() || this.kioskMode.isKioskMode()) {
			return of(false);
		}

		return this.idCardService.getIDCardDetails().pipe(
			map((result: any) => {
				if (result?.results?.digital_id_card) {
					const cardDetails = result.results.digital_id_card;
					switch (cardDetails.visible_to_who) {
						case 'Staff only':
							return this.user.isTeacher() || this.user.isAdmin();
						case 'Students only':
							return this.user.isStudent();
						case 'Students and Staff':
							return true;
						default:
							return false;
					}
				}
				return false;
			}),
			catchError(() => of(false))
		);
	}
	getIcon(iconName: string, setting: Setting): string {
		return this.darkTheme.getIcon({
			iconName: iconName,
			setting: setting,
		});
	}

	getColor(dark: string, white: string): string {
		return this.darkTheme.getColor({
			dark,
			white,
		});
	}

	// TODO there are other places in the code base that has similar code to this.
	// Will had a suggestion for how to reduce the duplicate code in the PR here:
	// https://github.com/smartpass/smartpass-frontend/pull/43#discussion_r1663145856
	// We decided to leave as is for now and revisit it when the settings menu is redesigned.
	handleAction(setting: Setting): void {
		// this will handle any menu links that need to open in a modal
		if (this.dialogRef && Object.keys(this.dialogRef).length > 0) {
			this.dialogRef.close(setting.action);
		} else {
			this.sideNavService.sideNavAction$.next(setting.action);
			this.sideNavService.toggle$.next(false);
		}
		switch (setting.action) {
			case SettingAction.About:
				window.open('https://smartpass.app/about');
				break;
			case SettingAction.Bug:
				window.open('https://www.smartpass.app/bugreport');
				break;
			case SettingAction.Classes:
				this.router.navigateByUrl('/main/classes');
				break;
			case SettingAction.GetStarted:
				this.router.navigate(['admin/gettingstarted']);
				break;
			case SettingAction.IdCard:
				break;
			case SettingAction.Privacy:
				window.open('https://www.smartpass.app/privacy?new=true');
				break;
			case SettingAction.SignOut:
				console.log('navigating to sign-out');
				this.router.navigate(['sign-out']);
				break;
			case SettingAction.Support:
				window.open('https://www.smartpass.app/support');
				break;
			case SettingAction.SwagShop:
				window.open('https://shop.smartpass.app/');
				break;
			case SettingAction.Switch:
				this.router.navigate(['main', 'passes']);
				break;
			case SettingAction.Term:
				window.open('https://www.smartpass.app/terms');
				break;
			case SettingAction.WishList:
				window.open('https://wishlist.smartpass.app');
				break;
		}
	}

	private updateDialogPosition(): void {
		const matDialogConfig: MatDialogConfig = new MatDialogConfig();
		let scrollAdjustment = 0;
		if (this.adjustForScroll) {
			scrollAdjustment = Math.abs(document.scrollingElement.getClientRects()[0].top);
		}
		if (this.targetElementRef && this.dialogRef) {
			const rect = this.targetElementRef.nativeElement.getBoundingClientRect();
			matDialogConfig.position = { left: `${rect.left + rect.width / 2 - 230}px`, top: `${rect.bottom + scrollAdjustment + 10}px` };
			this.dialogRef.updatePosition(matDialogConfig.position);
		}
	}

	signOutAction(): void {
		if (this.dialogRef && Object.keys(this.dialogRef).length > 0) {
			this.dialogRef.close('signout');
		} else {
			this.sideNavService.sideNavAction$.next(SettingAction.SignOut);
		}
		this.removeOfflineAuthData();
		localStorage.removeItem('fcm_sw_registered');
		this.localize.setLanguageUntranslated();
	}

	switchAction(): void {
		// #combinedTeacherAdminNux
		const updatedData = {
			...this.introsData,
			seen_combined_teacher_admin_view: {},
		};
		this.store.dispatch(updateIntrosSeenCombinedTeacherAdminViewLocalReset({ data: updatedData }));
	}

	private removeOfflineAuthData(): void {
		if (this.dialogRef) {
			this.dialogRef.close('signout');
		}

		combineLatest([this.pwaStorage.removeItem('server'), this.pwaStorage.removeItem('authData'), this.pwaStorage.removeItem('current-kiosk-room')])
			.pipe(takeUntil(this.destroy$))
			.subscribe();
		this.authenticationService.logout();
	}

	openLink(action: SettingAction): void {
		if (action === SettingAction.Privacy) {
			if (this.dialogRef && Object.keys(this.dialogRef).length > 0) {
				this.dialogRef.close(SettingAction.Privacy);
			} else {
				this.sideNavService.sideNavAction$.next(SettingAction.Privacy);
			}
		} else if (action === SettingAction.Term) {
			if (this.dialogRef && Object.keys(this.dialogRef).length > 0) {
				this.dialogRef.close(SettingAction.Term);
			} else {
				this.sideNavService.sideNavAction$.next(SettingAction.Term);
			}
		}
	}

	private async initializeSettings(): Promise<void> {
		this.settingsInitialized = false;
		const IDCardEnabled = await this.getIdCardEnabled().toPromise();
		this.isKioskMode = this.kioskMode.isKioskMode();
		this.settings.push({
			hidden: this.isKioskMode,
			background: '#6651FF',
			icon: 'user',
			action: SettingAction.Profile,
			title: 'My Profile',
		});
		this.settings.push({
			hidden: this.user.isAssistant() || !IDCardEnabled || this.isKioskModeSettingsPage || this.isKioskMode || this.isParent,
			background: '#6651FF',
			icon: 'id-card-02',
			action: SettingAction.IdCard,
			title: 'My ID Card',
		});
		this.settings.push({
			hidden: this.isKioskMode || this.user.isStudent(),
			background: '#6651FF',
			icon: 'group',
			action: SettingAction.Groups,
			title: 'Student Groups',
		});
		this.settings.push({
			hidden: !this.currentSchool.feature_flags_v2.includes('schedules') || this.user.isStudent() || this.isKioskMode,
			background: '#134482',
			icon: 'class',
			action: SettingAction.Classes,
			title: 'Classes',
		});
		this.settings.push({
			hidden: false,
			background: '#134482',
			icon: 'appearance',
			action: SettingAction.Appearance,
			title: 'Appearance',
		});
		this.settings.push({
			hidden: false,
			background: '#134482',
			icon: 'globe',
			action: SettingAction.Language,
			title: 'Language',
			isNew: false,
		});
		this.settings.push({
			hidden: !this.userService.getFeatureFlagAlertSounds(),
			background: '',
			icon: 'volume',
			action: SettingAction.Notifications,
			title: 'Notifications',
			isNew: false,
		});
		if (this.isStaff) {
			this.settings.push({
				hidden: this.isKioskMode,
				background: '#12C29E',
				icon: 'password',
				action: SettingAction.MyPin,
				title: 'Approval Pin',
			});
		}
		this.settings.push({
			hidden: this.isKioskMode,
			background: '#EBBB00',
			icon: 'star',
			action: SettingAction.Favorite,
			title: 'Favorites',
		});
		this.settings.push({
			hidden: true,
			background: '#EBBB00',
			icon: 'gift',
			action: SettingAction.Refer,
			title: 'Refer a teacher',
			isNew:
				this.isStaff && this.introsData?.referral_reminder
					? !this.introsData?.referral_reminder.universal.seen_version && this.showNotificationBadge
					: false,
		});
		this.settings.push({
			hidden: this.isKioskMode || this.user.isStudent(),
			background: '#134482',
			icon: 'gift',
			action: SettingAction.ReferSchool,
			title: 'Share SmartPass',
			isNew: false,
			tag: !this.introsData?.share_smartpass?.universal?.seen_version ? 'TRY IT' : '',
		});
		if (!this.isKioskMode) {
			this.settings.push({
				hidden: this.user.isStudent(),
				background: '#134482',
				icon: 'shirt',
				action: SettingAction.SwagShop,
				title: 'Swag Shop',
				isNew: false,
			});
		}
		this.settingsInitialized = true;
	}
}
