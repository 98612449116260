import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';
import {
	SchoolActivityInstance,
	SchoolActivityInstancesForPeriodReq,
	SchoolActivityInstancesReq,
	SchoolActivityService,
} from '../../../services/school-activity.service';
import { ToastService } from '../../../services/toast.service';
import { AddSchoolActivityFailureAction, GetSchoolActivitiesFailureAction } from '../../school-activities/actions/school-activities.actions';
import {
	CreateSchoolActivityInstancesBulkFailureAction,
	CreateSchoolActivityInstancesBulkSuccessAction,
	CreateSchoolActivityInstanceSuccessAction,
	GetSchoolActivityInstancesSuccessAction,
	RemoveSchoolActivityInstanceFailureAction,
	RemoveSchoolActivityInstanceSuccessAction,
	SchoolActivityInstancesActionTypes,
} from '../actions/school-activities-instances.actions';

@Injectable()
export class SchoolActivityInstancesEffects {
	loadSchoolActivityInstances$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivityInstancesActionTypes.GetSchoolActivityInstances),
			exhaustMap(({ req }: { req: SchoolActivityInstancesReq }) => {
				return this.schoolActivityService.GetActivityInstancesByIdAndFillExtraHTTP(req).pipe(
					map((instances: SchoolActivityInstance[]) => GetSchoolActivityInstancesSuccessAction({ instances })),
					catchError((error) => of(GetSchoolActivitiesFailureAction({ errorMessage: error })))
				);
			})
		);
	});

	loadSchoolActivityInstancesForFlexPeriod$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivityInstancesActionTypes.GetSchoolActivityInstancesForFlexPeriod),
			exhaustMap(({ req }: { req: SchoolActivityInstancesForPeriodReq }) => {
				return this.schoolActivityService.GetActivityInstancesByPeriodAndFillExtraHTTP(req).pipe(
					map((instances: SchoolActivityInstance[]) => GetSchoolActivityInstancesSuccessAction({ instances })),
					catchError((error) => of(GetSchoolActivitiesFailureAction({ errorMessage: error })))
				);
			})
		);
	});

	createActivityInstancesBulk$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivityInstancesActionTypes.CreateSchoolActivityInstancesBulk),
			exhaustMap(({ instanceReqs, removeFakeInstances }) => {
				return from(instanceReqs).pipe(
					mergeMap(({ start, end, activityId, state, selected }) => {
						return this.schoolActivityService.CreateActivityInstanceHTTP(start, end, activityId, state).pipe(
							map((newActivityInstance) =>
								CreateSchoolActivityInstancesBulkSuccessAction({
									instance: newActivityInstance,
									selectInstance: selected,
									removeFakeInstances: removeFakeInstances,
								})
							),
							catchError((error) => of(CreateSchoolActivityInstancesBulkFailureAction({ error: error.message })))
						);
					})
				);
			})
		);
	});

	createActivityInstance$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivityInstancesActionTypes.CreateSchoolActivityInstance),
			exhaustMap(({ start, end, activityId, state }) => {
				return this.schoolActivityService.CreateActivityInstanceHTTP(start, end, activityId, state).pipe(
					map((newActivityInstance) => CreateSchoolActivityInstanceSuccessAction({ instance: newActivityInstance })),
					catchError((error) => of(AddSchoolActivityFailureAction({ error: error.message })))
				);
			})
		);
	});

	removeActivityInstance$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivityInstancesActionTypes.RemoveSchoolActivityInstance),
			exhaustMap(({ instanceId }) => {
				if (instanceId < 0) {
					return of(RemoveSchoolActivityInstanceSuccessAction({ instanceId }));
				}
				return this.schoolActivityService.DeleteActivityInstanceHTTP(instanceId).pipe(
					map(() => {
						this.toastService.openToast({ title: `Instance removed`, type: 'success' });
						return RemoveSchoolActivityInstanceSuccessAction({ instanceId });
					}),
					catchError((error) => {
						this.toastService.openToast({ title: `Issue encountered while deleting instance, try again`, type: 'error' });
						return of(RemoveSchoolActivityInstanceFailureAction({ error: error.message }));
					})
				);
			})
		);
	});

	constructor(private actions$: Actions, private schoolActivityService: SchoolActivityService, private toastService: ToastService) {}
}
