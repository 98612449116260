import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as moment from 'moment';
import { SchoolActivityInstance } from '../../../services/school-activity.service';
import {
	AddSchoolActivityInstanceAction,
	ClearSelectedActivityInstanceAction,
	CreateSchoolActivityInstancesBulkSuccessAction,
	CreateSchoolActivityInstanceSuccessAction,
	GetSchoolActivityInstancesForFlexPeriodSuccessAction,
	GetSchoolActivityInstancesSuccessAction,
	RemoveAllSchoolActivityInstancesAction,
	RemoveSchoolActivityInstanceSuccessAction,
	SelectActivityInstanceAction,
	UpdateSchoolActivityInstanceSuccessAction,
} from '../actions/school-activities-instances.actions';

export interface SchoolActivityInstancesState extends EntityState<SchoolActivityInstance> {
	selectedActivityInstanceId: number | null;
}
export const schoolActivityInstancesInitialState: SchoolActivityInstancesState = {
	ids: [],
	entities: {},
	selectedActivityInstanceId: null,
};

export const schoolActivityInstanceAdapter: EntityAdapter<SchoolActivityInstance> = createEntityAdapter<SchoolActivityInstance>();

const reducer = createReducer(
	schoolActivityInstancesInitialState,
	on(GetSchoolActivityInstancesSuccessAction, (state, { instances }) => schoolActivityInstanceAdapter.setAll(instances, state)),
	on(GetSchoolActivityInstancesForFlexPeriodSuccessAction, (state, { instances }) => schoolActivityInstanceAdapter.setAll(instances, state)),
	on(RemoveSchoolActivityInstanceSuccessAction, (state, { instanceId }) => {
		return schoolActivityInstanceAdapter.removeOne(instanceId, state);
	}),
	on(SelectActivityInstanceAction, (state, { instanceId }) => ({
		...state,
		selectedActivityInstanceId: instanceId,
	})),
	on(ClearSelectedActivityInstanceAction, (state) => ({
		...state,
		selectedActivityInstanceId: null,
	})),
	on(RemoveAllSchoolActivityInstancesAction, (state) => schoolActivityInstanceAdapter.removeAll(state)),
	on(AddSchoolActivityInstanceAction, (state, { instance }) => schoolActivityInstanceAdapter.addOne(instance, state)),
	on(CreateSchoolActivityInstanceSuccessAction, (state, { instance }) => {
		if (instance.state !== 'canceled') {
			return schoolActivityInstanceAdapter.addOne(instance, state);
		} else {
			return state;
		}
	}),
	on(UpdateSchoolActivityInstanceSuccessAction, (state, { instance }) => schoolActivityInstanceAdapter.upsertOne(instance, state)),
	on(CreateSchoolActivityInstancesBulkSuccessAction, (state, { instance, selectInstance, removeFakeInstances }) => {
		if (selectInstance) {
			schoolActivityInstanceAdapter.addOne(instance, state);
			return {
				...state,
				selectedActivityInstanceId: instance.id,
			};
		}
		// This is used in the case of an activity being switched from recurring to scheduled, when days are added, we want to remove the fake instances from the store.
		if (removeFakeInstances) {
			state.entities = Object.values(state.entities).reduce((acc, instance) => {
				if (instance && instance.id > 0) {
					acc[instance.id] = instance;
				}
				return acc;
			}, {} as Dictionary<SchoolActivityInstance>);
		}
		// If a fake instance is being turned into a real instance, we want to update the fake instance with the real instance.
		const existing = Object.values(state.entities).find((i) => moment(i?.start_time).isSame(moment(instance.start_time), 'minute'));
		if (existing && existing.id < 0) {
			return schoolActivityInstanceAdapter.updateOne({ id: existing.id, changes: instance }, state);
		}
		return schoolActivityInstanceAdapter.addOne(instance, state);
	})
);

export function schoolActivitiesInstancesReducer(state: SchoolActivityInstancesState, action: Action) {
	return reducer(state, action);
}
