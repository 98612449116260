import * as React from 'react';
import { OptionsMenu, MenuOption, ConfirmationDialog } from '../../../components';

interface ITitleHeaderProps {
	name: string;
	editFlexPeriod: () => void | undefined;
	deleteFlexPeriod: () => void;
}

export function TitleHeader({ name, editFlexPeriod, deleteFlexPeriod }: ITitleHeaderProps): React.ReactElement {
	const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);

	const showConfirm = () => setIsConfirmOpen(true);
	const hideConfirm = () => setIsConfirmOpen(false);

	const menuOptions: MenuOption[] = [
		{
			name: 'Delete Flex Period (All Sessions)',
			action: showConfirm,
			danger: true,
			icon: './assets/Delete (Red).svg',
		},
	];

	if (editFlexPeriod) {
		menuOptions.unshift({
			name: 'Edit Flex Period',
			icon: './assets/Edit (Gray).svg',
			action: editFlexPeriod,
		});
	}

	return (
		<div className="tw-flex tw-items-center tw-justify-between tw-mb-5">
			<div className="tw-font-sans tw-text-xl tw-text-navy-500 tw-font-bold">{name}</div>
			<OptionsMenu options={menuOptions} className="tw-w-70">
				<img
					className="tw-h-4 tw-cursor-pointer tw-p-2 tw-rounded-full tw-bg-gray-100 tw-bg-opacity-50 hover:tw-bg-opacity-75"
					src="./assets/Dots (Blue-Gray).svg"
				/>
			</OptionsMenu>
			<ConfirmationDialog
				showModal={isConfirmOpen}
				onConfirm={deleteFlexPeriod}
				onCancel={hideConfirm}
				title={`Delete '${name}' (All Sessions)?`}
				description="When you delete a flex period, activity sessions that have students in them will still exist, but new sessions or activities can't be created."
				confirmText="Delete Flex Period"
			/>
		</div>
	);
}
