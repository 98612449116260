<div class="sc-search-container" [ngClass]="{ 'schedules-ff': hasSchedulesFF, 'no-schedules-ff': !hasSchedulesFF }">
	<div *ngIf="loading" class="sc-loading-box">
		<mat-spinner></mat-spinner>
	</div>
	<div style="position: relative" *ngIf="!loading">
		<div class="search-box-container" [ngClass]="{ inactive: !isSearchFocused, full: overLimit > 0 }" (click)="setFocusOnSearch()">
			<div *ngFor="let selection of selectedGroupsAndStudents">
				<app-selection [selection]="selection" [isUser]="selection.display_name" (removeSelectionEvent)="removeSelection($event)"></app-selection>
			</div>
			<input
				#searchInput
				class="dynamic-search-box"
				[placeholder]="selectedGroupsAndStudents.length === 0 ? 'Search students & groups' : ''"
				[(ngModel)]="searchText"
				(input)="searchInputChanges$.next($event.target.value)"
				(focus)="onSearchFocus(true)"
				(blur)="onSearchFocus(false)" />
		</div>
		<div
			*ngIf="isSearchFocused && (searchResults?.length !== 0 || searchText.length === 0 || usersLoaded) && overLimit <= 0"
			class="sc-search-results groups-students-list">
			<mat-list [style.padding-top]="'0px'">
				<div *ngIf="selectedGroupsAndStudents.length === 0 || (searchText.length > 0 && usersLoaded)">
					<mat-list-item
						*ngFor="let group of groups"
						class="sc-search group"
						(mousedown)="onSelect(group)"
						[style.background-color]="getBackground(_item)"
						#_item>
						<div class="sc-search sc-search-selection">
							<img class="group-icon" src="../../../../../assets/Groups Icon.svg" />
							<span class="sc-search name">{{ group.title }}</span>
							<span class="number-of-students">
								{{ group.users.length }}
								<ng-container [ngPlural]="group.users.length">
									<ng-template ngPluralCase="=1"> student</ng-template>
									<ng-template ngPluralCase="other"> students</ng-template>
								</ng-container>
							</span>
							<img
								*ngIf="canEditGroup && _item.hovered"
								class="edit-icon"
								src="../../../../../assets/Edit (Blue-Gray).svg"
								(mousedown)="editGroup($event, group)" />
						</div>
					</mat-list-item>
				</div>
				<mat-list-item
					*ngFor="let result of searchResults"
					class="sc-search group"
					[ngClass]="{ 'cant-add': !result.overwriteable }"
					(mousedown)="onSelect(result)"
					#_item>
					<div
						class="sc-search sc-search-selection"
						[ngClass]="{ 'cant-add': !result.overwriteable }"
						[matTooltip]="result.message"
						matTooltipClass="sc-tooltip"
						matTooltipPosition="below">
						<div
							*ngIf="result.user.profile_picture; else d"
							[ngStyle]="{ background: 'url(' + result.user.profile_picture + ') no-repeat left center/cover' }"
							class="profile-picture"></div>
						<ng-template #d>
							<img class="profile-picture" src="../../../../../assets/Avatar Default.svg" alt="Avatar" />
						</ng-template>
						<span class="sc-search name">{{ result.user.display_name }}</span>
						<div class="sc-search message" *ngIf="result?.activityName">
							<img src="../../../../../assets/Check (Gray).svg" alt="Already in" /><span
								>{{ result?.alreadyIn ? 'Already in' : 'In' }} {{ result.activityName }}</span
							>
						</div>
						<ng-template #tooltip>
							<div class="sc-tooltip">{{ result.message }}</div>
						</ng-template>
					</div>
				</mat-list-item>
				<div *ngIf="searchResults?.length === 0 && searchText?.length > 0 && usersLoaded" class="no-results-found-container">No Results Found</div>
				<div *ngIf="searchText?.length === 0 && selectedGroupsAndStudents?.length === 0" class="no-results-found-container empty-search"></div>

				<div *ngIf="selectedGroupsAndStudents?.length === 0 && searchText?.length === 0" class="upload-students-button-container">
					<div class="upload-students-button" (mousedown)="showImportStudentList()">Upload a list of students</div>
				</div>
			</mat-list>
		</div>
		<div *ngIf="overLimit > 0" class="occupancy-warning">
			<img src="../../../../../assets/barcodeScanErrorIcon.svg" />
			<div>Only {{ spotsRemaining }} spot<span *ngIf="spotsRemaining !== 1">s</span> left in this activity.</div>
		</div>
		<div class="overwriteable-list" *ngIf="overrideableStudents.length > 0">
			<div class="sc-add-all-header">
				<img src="../../../../../assets/Info (Blue-Gray).svg" />
				<div>
					<div>
						{{ overrideableStudents.length }}
						<ng-container [ngPlural]="overrideableStudents.length">
							<ng-template ngPluralCase="=1"> Student</ng-template>
							<ng-template ngPluralCase="other"> Students</ng-template>
						</ng-container>
						Not Added
					</div>
					<span
						>They are already in
						<ng-container [ngPlural]="overrideableStudents.length">
							<ng-template ngPluralCase="=1"> another activity</ng-template>
							<ng-template ngPluralCase="other"> other activities</ng-template> </ng-container
						>.
					</span>
				</div>
				<div
					class="sc-add-all-btn"
					[ngClass]="{ 'cant-add': overrideAllDisabled }"
					(click)="!overrideAllDisabled && overrideAll()"
					[matTooltip]="overrideAllDisabled ? 'One or more students can\'t be added.' : ''"
					matTooltipClass="sc-tooltip"
					matTooltipPosition="below">
					Add Anyway
				</div>
			</div>
			<div *ngFor="let result of overrideableStudents">
				<mat-list-item
					class="sc-search group"
					(click)="overrideStudent(result.user.id)"
					[style.background-color]="getBackground(_item)"
					#_item
					[ngClass]="{ 'cant-add': !result.overwriteable }">
					<div
						class="sc-search sc-search-selection"
						[ngClass]="{ 'cant-add': !result.overwriteable }"
						[style.opacity]="result.overwriteable ? '100%' : '50%'"
						[matTooltip]="result.message || ''"
						matTooltipClass="sc-tooltip"
						matTooltipPosition="below">
						<div
							*ngIf="result.user.profile_picture; else d"
							[ngStyle]="{ background: 'url(' + result.user.profile_picture + ') no-repeat left center/cover' }"
							class="profile-picture"></div>
						<ng-template #d>
							<img class="profile-picture" src="../../../../../assets/Avatar Default.svg" alt="Avatar" />
						</ng-template>
						<span class="sc-search name">{{ result.user.display_name }}</span>
						<div class="sc-search message" *ngIf="result?.activityName">
							<img src="../../../../../assets/Check (Gray).svg" alt="Yeah" /><span
								>{{ result?.alreadyIn ? 'Already in' : 'In' }} {{ result.activityName }}</span
							>
						</div>
					</div>
				</mat-list-item>
			</div>
		</div>
		<div class="footer-list-bar" *ngIf="overrideableStudents.length > 0"></div>
		<div class="attendee-add-footer">
			<div>After you add students to this session, only an admin can reschedule them.</div>
		</div>
	</div>
</div>
