import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concat, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import * as introsActions from '../actions';
import { IntroData, IntroType } from '../state';

@Injectable()
export class IntrosEffects {
	getIntros$ = createEffect(() => {
		return this.action$.pipe(
			ofType(introsActions.getIntros),
			switchMap(() => {
				return this.userService.getIntros().pipe(
					map((intros) => {
						return introsActions.getIntrosSuccess({ data: intros as IntroData });
					}),
					catchError((error) => of(introsActions.getIntrosFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	updateIntros$ = createEffect(() => {
		return this.action$.pipe(
			ofType(introsActions.updateIntros),
			switchMap((action) => {
				return this.userService.updateIntros(IntroType.ReferralReminder, action.device, action.version).pipe(
					map(() => {
						const updatedData: IntroData = {
							...action.intros,
							referral_reminder: {
								[action.device]: { seen_version: action.version },
							},
						};
						return introsActions.updateIntrosSuccess({ data: updatedData });
					}),
					catchError((error) => of(introsActions.updateIntrosFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	updateIntrosReferSchool$ = createEffect(() => {
		return this.action$.pipe(
			ofType(introsActions.updateIntrosShareSmartpass),
			switchMap((action) => {
				return this.userService.updateIntros(IntroType.ShareSmartpass, action.device, action.version).pipe(
					map(() => {
						const updatedData = {
							...action.intros,
							share_smartpass: {
								[action.device]: { seen_version: action.version },
							},
						};
						return introsActions.updateIntrosShareSmartpassSuccess({ data: updatedData });
					}),
					catchError((error) => of(introsActions.updateIntrosShareSmartpassFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	updateIntroStudentPassLimit$ = createEffect(() => {
		return this.action$.pipe(
			ofType(introsActions.updateIntrosStudentPassLimits),
			switchMap((action) => {
				return this.userService.updateIntros(IntroType.StudentPassLimit, action.device, action.version).pipe(
					map(() => {
						const updatedData = {
							...action.intros,
							student_pass_limit: {
								[action.device]: { seen_version: action.version },
							},
						};
						return introsActions.updateIntrosStudentPassLimitsSuccess({ data: updatedData });
					}),
					catchError((error) => of(introsActions.updateIntrosStudentPassLimitsFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	updateIntroAdminSideBarNux$ = createEffect(() => {
		return this.action$.pipe(
			ofType(introsActions.updateIntrosAdminSideBarNux),
			switchMap((action) => {
				return this.userService.updateIntros(IntroType.AdminSideBarNux, action.device, action.version).pipe(
					map(() => {
						const updatedData = {
							...action.intros,
							admin_side_bar_nux: {
								[action.device]: { seen_version: action.version },
							},
						};
						return introsActions.updateIntrosAdminSideBarNuxSuccess({ data: updatedData });
					}),
					catchError((error) => of(introsActions.updateIntrosAdminSideBarNuxFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	updateIntroAdminPassLimitsMessage$ = createEffect(() => {
		return this.action$.pipe(
			ofType(introsActions.updateIntrosAdminPassLimitsMessage),
			switchMap((action) => {
				return this.userService.updateIntros(IntroType.AdminPassLimit, action.device, action.version).pipe(
					map(() => {
						const updatedData = {
							...action.intros,
							admin_pass_limit_message: {
								[action.device]: { seen_version: action.version },
							},
						};
						return introsActions.updateIntrosAdminPassLimitsMessageSuccess({ data: updatedData });
					}),
					catchError((error) => of(introsActions.updateIntrosAdminPassLimitsMessageFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	// #combinedTeacherAdminNux
	updateIntrosCombinedTeacherAdminView$ = createEffect(() => {
		return this.action$.pipe(
			ofType(introsActions.updateIntrosSeenCombinedTeacherAdminView),
			switchMap((action) => {
				const updatedData = {
					...action.intros,
					seen_combined_teacher_admin_view: {
						[action.device]: { seen_version: action.version },
					},
				};

				return concat(
					// Optimistically clear the local value; don't wait for the API
					of(introsActions.updateIntrosSeenCombinedTeacherAdminViewLocalReset({ data: updatedData })),
					this.userService.updateIntros(IntroType.SeenCombinedTeacherAdminView, action.device, action.version).pipe(
						map(() => introsActions.updateIntrosSeenCombinedTeacherAdminViewSuccess({ data: updatedData })),
						catchError((error) => of(introsActions.updateIntrosSeenCombinedTeacherAdminViewFailure({ errorMessage: error.message })))
					)
				);
			})
		);
	});

	updateNewAdminHasSeenGetStarted$ = createEffect(() => {
		return this.action$.pipe(
			ofType(introsActions.updateNewAdminHasSeenGetStarted),
			switchMap((action) => {
				return this.userService.updateIntros(IntroType.NewAdminHasSeenGetStarted, action.device, action.version).pipe(
					map(() => {
						const updatedData = {
							...action.intros,
							new_admin_has_seen_get_started: {
								[action.device]: { seen_version: action.version },
							},
						};
						return introsActions.updateNewAdminHasSeenGetStartedSuccess({ data: updatedData });
					}),
					catchError((error) => of(introsActions.updateNewAdminHasSeenGetStartedFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	updateIntrosSeenNotificationAlertsSettings$ = createEffect(() => {
		return this.action$.pipe(
			ofType(introsActions.updateIntrosSeenNotificationAlertsSettingsAction),
			switchMap((action) => {
				return this.userService.updateIntros(IntroType.SeenNotificationAlertSettings, action.device, action.version).pipe(
					map(() => {
						const updatedData = {
							...action.intros,
							seen_notification_alerts_settings: {
								[action.device]: { seen_version: action.version },
							},
						};
						return introsActions.updateIntrosSeenNotificationAlertsSettingsSuccess({ data: updatedData });
					}),
					catchError((error) => of(introsActions.updateIntrosSeenNotificationAlertsSettingsFailure({ errorMessage: error.message })))
				);
			})
		);
	});

	constructor(private action$: Actions, private userService: UserService) {}
}
