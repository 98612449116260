import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import {
	CreateSchoolActivityInstanceReq,
	CreateSchoolActivityReq,
	InstanceTimes,
	SchoolActivity,
	SchoolActivityService,
} from '../../../services/school-activity.service';
import { ToastService } from '../../../services/toast.service';
import {
	AddSchoolActivityFailureAction,
	AddSchoolActivitySuccessAction,
	DeleteSchoolActivityFailureAction,
	DeleteSchoolActivitySuccessAction,
	GetSchoolActivitiesByStatusFailureAction,
	GetSchoolActivitiesByStatusSuccessAction,
	GetSchoolActivitiesFailureAction,
	GetSchoolActivitiesSuccessAction,
	GetSchoolActivityByIdFailureAction,
	GetSchoolActivityByIdSuccessAction,
	SchoolActivitiesActionTypes,
	UpdateSchoolActivityFailureAction,
	UpdateSchoolActivitySuccessAction,
} from '../actions/school-activities.actions';

@Injectable()
export class SchoolActivitiesEffects {
	addSchoolActivity$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivitiesActionTypes.AddSchoolActivity),
			exhaustMap(({ schoolActivity, instanceTimes }: { schoolActivity: CreateSchoolActivityReq; instanceTimes: InstanceTimes[] }) =>
				this.schoolActivityService.CreateActivityHTTP(schoolActivity).pipe(
					switchMap((newActivity) => {
						this.toastService.openToast({ title: `Activity successfully created!`, type: 'success' });
						if (instanceTimes.length) {
							const reqs: CreateSchoolActivityInstanceReq[] = instanceTimes.map((time) => {
								return { start: time.start, end: time.end, activityId: newActivity.id, state: schoolActivity.state, selected: false };
							});
							this.schoolActivityService.CreateActivityInstanceBulk(reqs, false);
						}
						return of(AddSchoolActivitySuccessAction({ schoolActivity: newActivity }));
					}),
					catchError((error) => {
						this.toastService.openToast({ title: `Error while creating activity, try again.`, type: 'error' });
						return of(AddSchoolActivityFailureAction({ error: error.message }));
					})
				)
			)
		);
	});

	loadSchoolActivities$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivitiesActionTypes.GetSchoolActivities),
			exhaustMap(({ flex_period_id }) => {
				return this.schoolActivityService.GetActivitiesHTTP(flex_period_id).pipe(
					map((schoolActivities: SchoolActivity[]) => GetSchoolActivitiesSuccessAction({ schoolActivities })),
					catchError((error) => of(GetSchoolActivitiesFailureAction({ errorMessage: error })))
				);
			})
		);
	});

	loadSchoolActivitiesByStatus$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivitiesActionTypes.GetSchoolActivitiesByStatus),
			exhaustMap(({ status, flexPeriodId }) => {
				return this.schoolActivityService.GetActivitiesByStatusHTTP(status, flexPeriodId).pipe(
					map((schoolActivities: SchoolActivity[]) => GetSchoolActivitiesByStatusSuccessAction({ schoolActivities })),
					catchError((error) => of(GetSchoolActivitiesByStatusFailureAction({ errorMessage: error })))
				);
			})
		);
	});

	getSchoolActivityById$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivitiesActionTypes.GetSchoolActivityById),
			exhaustMap(({ activityId }) => {
				return this.schoolActivityService.GetActivityByIdHTTP(activityId).pipe(
					map((schoolActivity: SchoolActivity) => {
						return GetSchoolActivityByIdSuccessAction({ schoolActivity });
					}),
					catchError((error) => of(GetSchoolActivityByIdFailureAction({ errorMessage: error })))
				);
			})
		);
	});

	updateSchoolActivity$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivitiesActionTypes.UpdateSchoolActivity),
			exhaustMap(({ activity }) => {
				return this.schoolActivityService.UpdateActivityHTTP(activity).pipe(
					map((schoolActivity: SchoolActivity) => {
						return UpdateSchoolActivitySuccessAction({ schoolActivity });
					}),
					catchError((error) => of(UpdateSchoolActivityFailureAction({ errorMessage: error })))
				);
			})
		);
	});

	deleteSchoolActivity$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SchoolActivitiesActionTypes.DeleteSchoolActivity),
			exhaustMap(({ activityId }) => {
				return this.schoolActivityService.DeleteActivityHTTP(activityId).pipe(
					map(() => {
						this.toastService.openToast({ title: `Activity removed`, type: 'success' });
						return DeleteSchoolActivitySuccessAction({ activityId });
					}),
					catchError((error) => {
						this.toastService.openToast({ title: `Issue encountered while deleting activity, try again`, type: 'error' });
						return of(DeleteSchoolActivityFailureAction({ errorMessage: error }));
					})
				);
			})
		);
	});

	constructor(private actions$: Actions, private schoolActivityService: SchoolActivityService, private toastService: ToastService) {}
}
