<ng-container *ngIf="encounter && group">
	<div class="wrapper" #wrapper [@grow]="animationTrigger">
		<div class="header">
			<div class="date tw-flex tw-items-center">
				{{ encounter.pass_time | date }}
				<div *ngIf="encounter?.override_id" class="tw-bg-red-500 tw-text-white tw-ml-2 tw-font-medium tw-rounded-full tw-px-1.5 tw-py-0.5">
					Override
				</div>
			</div>
			<img
				width="18"
				style="cursor: pointer"
				(click)="setStartHeight()"
				[src]="'./assets/Chevron ' + (isOpen ? 'Up' : 'Down') + ' (Blue-Gray).svg' | resolveAsset"
				alt="Chevron" />
		</div>
		<div class="description" *ngIf="isOpen">
			<ul class="tw-pl-3">
				<li *ngFor="let desc of descs" class="tw-text-pretty">{{ desc }}</li>
			</ul>
		</div>
	</div>
</ng-container>
