import { ChangeDetectorRef, Component, ElementRef, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CreateFormService } from 'app/create-hallpass-forms/create-form.service';
import { ROLES, User } from 'app/models/User';
import { FeatureFlagService } from 'app/services/feature-flag.service';
import { HallPassesService } from 'app/services/hall-passes.service';
import { HomepageService } from 'app/services/homepage.service';
import { KioskModeService } from 'app/services/kiosk-mode.service';
import { ScheduleService } from 'app/services/schedule.service';
import { ScreenService } from 'app/services/screen.service';
import { UserService } from 'app/services/user.service';
import { GroupsAndStudentSelectComponent } from 'app/shared/shared-components/groups-and-student-select/groups-and-student-select.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, skip, takeUntil, tap } from 'rxjs/operators';

export interface OverridersAddRemoveRet {
	toAdd: User[];
	toRemove: User[];
}

@Component({
	selector: 'sp-encounter-overriders-add-remove',
	templateUrl: './encounter-overriders-add-remove.component.html',
	styleUrls: [
		'../../../../shared/shared-components/groups-and-student-select/groups-and-student-select.component.scss',
		'../../../../create-hallpass-forms/main-hallpass--form/student-groups/groups-container-v2/groups-container-v2.component.scss',
		'../../../../teacher-activity/attendees/student-instance-search/student-instance-search.component.scss',
		'./encounter-overriders-add-remove.component.scss',
	],
})
export class EncounterOverridersAddRemoveComponent extends GroupsAndStudentSelectComponent implements OnInit {
	@ViewChild('searchInput', { static: false }) searchInput: ElementRef<HTMLInputElement>;

	searchText = '';
	searchResults: User[] = [];
	loading = true;
	isSearchFocused = false;
	searchInputChanges$ = new Subject<string>();
	usersLoaded = false;
	selectedUsers: User[] = [];
	usersToRemove: User[] = [];
	private currentOverriders: User[] = [];

	constructor(
		public userService: UserService,
		public sanitizer: DomSanitizer,
		public formService: CreateFormService,
		public screenService: ScreenService,
		public dialog: MatDialog,
		private changeDetector: ChangeDetectorRef,
		public featureFlagService: FeatureFlagService,
		public scheduleService: ScheduleService,
		public pinnableService: HallPassesService,
		public homePageService: HomepageService,
		public kioskService: KioskModeService,
		@Optional() private overrideRef: MatDialogRef<EncounterOverridersAddRemoveComponent>
	) {
		super(
			userService,
			sanitizer,
			formService,
			screenService,
			dialog,
			featureFlagService,
			scheduleService,
			pinnableService,
			homePageService,
			kioskService
		);
	}

	ngOnInit(): void {
		this.frameMotion$ = this.formService.getFrameMotionDirection();
		this.searchInputChanges$
			.pipe(
				tap(() => (this.usersLoaded = false)),
				distinctUntilChanged(),
				debounceTime(300),
				takeUntil(this.destroy$),
				tap((search) => {
					this.onSearch(search);
				})
			)
			.subscribe();
		this.userService
			.getAccountsRoles(ROLES.OverriderEncounter, '', 1000)
			?.pipe(skip(1))
			.subscribe((res: User[]) => {
				this.currentOverriders = res;
				this.selectedUsers = [...this.currentOverriders];
				this.loading = false;
			});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.setFocusOnSearch();
			this.changeDetector.detectChanges();
		});
	}

	onSearch(searchText: string): void {
		if (searchText !== '') {
			this.userService
				.searchProfile(ROLES.Teacher, 100, searchText)
				.pipe(
					map((responses) => {
						const filteredTeachers: User[] = [];
						if (responses.results?.length > 0) {
							for (const res of responses.results) {
								const user = User.fromJSON(res);
								if (!this.selectedUsers.some((selectedUser) => user.id === selectedUser.id)) {
									filteredTeachers.push(user);
								}
							}
						}
						return filteredTeachers;
					}),
					takeUntil(this.destroy$),
					finalize(() => (this.usersLoaded = true))
				)
				.subscribe((filteredTeachers) => (this.searchResults = filteredTeachers));
		} else {
			this.cleanUpSearch();
			this.searchResults = [];
		}
	}

	onSelect(userToAdd: User): void {
		this.selectedUsers.push(userToAdd);
		if (this.usersToRemove.some((userToRemove) => userToRemove.id === userToAdd.id)) {
			this.usersToRemove = this.usersToRemove.filter((user) => user.id !== userToAdd.id);
		}
		this.cleanUpSearch();
	}

	onRemove(selection: User): void {
		this.selectedUsers = this.selectedUsers.filter((s) => s.id !== selection.id);
		if (this.currentOverriders.some((overrider) => overrider.id === selection.id)) {
			this.usersToRemove.push(selection);
		}
		this.cleanUpSearch();
	}

	private cleanUpSearch(): void {
		this.searchText = '';
		this.searchResults = [];
		this.setFocusOnSearch();
	}

	onSearchFocus(isFocused: boolean): void {
		setTimeout(() => (this.isSearchFocused = isFocused), 50);
	}

	private setFocusOnSearch(): void {
		if (this.searchInput?.nativeElement) {
			setTimeout(() => this.searchInput.nativeElement.focus(), 50);
		}
	}

	onSubmit(): void {
		this.loading = true;
		const usersToAdd = this.selectedUsers.filter((selectedUser) => !this.currentOverriders.some((overrider) => overrider.id === selectedUser.id));
		const ret: OverridersAddRemoveRet = { toAdd: usersToAdd, toRemove: this.usersToRemove };
		this.overrideRef.close(ret);
	}
}
