export type IntroDeviceTypes = 'universal' | 'ios' | 'android' | 'web';
export type SingleIntro = Partial<Record<IntroDeviceTypes, { seen_version: string }>>;

export enum IntroType {
	ReferralReminder = 'referral_reminder',
	ShareSmartpass = 'share_smartpass',
	AdminPassLimit = 'admin_pass_limit_message',
	StudentPassLimit = 'student_pass_limit',
	SeenCombinedTeacherAdminView = 'seen_combined_teacher_admin_view',
	SeenNotificationAlertSettings = 'seen_notification_alerts_settings',
	NewAdminHasSeenGetStarted = 'new_admin_has_seen_get_started',
	AdminSideBarNux = 'admin_side_bar_nux',
}

export interface IntroData {
	[key: string]: SingleIntro;
}

export interface IIntrosState {
	data: IntroData;
	loading: boolean;
	loaded: boolean;
}
