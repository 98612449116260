import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ConnectedPosition } from '@angular/cdk/overlay/position/flexible-connected-position-strategy';
import { ComponentPortal } from '@angular/cdk/portal';
import {
	ComponentRef,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
} from '@angular/core';
import { merge, of, race, Subject, timer } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomToolTipComponent } from '../../shared/shared-components/custom-tool-tip/custom-tool-tip.component';

@Directive({
	selector: '[customToolTip]',
})
export class ToolTipRendererDirective implements OnInit, OnDestroy, OnChanges {
	/**
	 * This will be used to show tooltip or not
	 * This can be used to show the tooltip conditionally
	 */
	@Input() showToolTip = true;
	@Input() nonDisappearing = true;
	@Input() position: 'mouse' | 'top' | 'bottom' | 'left' | 'right' | 'above' = 'bottom';
	@Input() additionalOffsetY = 0;
	@Input() additonalOffsetX = 0;
	@Input() editable = true;
	@Input() positionStrategy?: ConnectedPosition;
	@Input() width = 'auto';
	@Input() allowVarTag = false;
	@Input() toolTipShowDelay = 100;
	@Input() toolTipHideDelay = 100;

	// If this is specified then specified text will be showin in the tooltip
	@Input(`customToolTip`) text = '';

	// If this is specified then specified template will be rendered in the tooltip
	@Input() contentTemplate?: TemplateRef<unknown>;

	@Output() leave = new EventEmitter<void>();
	@Output() isOpen = new EventEmitter<boolean>();

	// destroy for tooltip component
	private destroyOpen$ = new Subject<void>();
	// destroy for this directive
	private destroy$ = new Subject<void>();

	private _overlayRef!: OverlayRef;
	private tooltipRef!: ComponentRef<CustomToolTipComponent>;
	private mousey = 0;
	private hideTooltipTimeout = 0;

	constructor(private _overlay: Overlay, private _overlayPositionBuilder: OverlayPositionBuilder, private _elementRef: ElementRef) {}

	ngOnInit() {
		// this.contentTemplate has a default template
		// simpleText in CustomToolTipComponent
		// this.text is necessary only when we use simpleText
		// otherwise the template can have its own text
		// per total, we dont't need this condition
		/*if (!this.contentTemplate && !this.text) {
      this.showToolTip = false;
    }*/
		if (!this.showToolTip) {
			return;
		}

		const positionStrategy = this._overlayPositionBuilder
			.flexibleConnectedTo(this._elementRef)
			.withPositions([this.positionStrategy ? this.positionStrategy : this.getPosition()]);

		const scrollStrategy = this._overlay.scrollStrategies.close();

		this._overlayRef = this._overlay.create({
			positionStrategy,
			scrollStrategy,
			panelClass: 'custom-tooltip',
		});

		// in case of the click event
		// this is (all time or most of the time?) followed by a hover event
		// resulting in a double call to this.show
		// and the second event triggers this.closeTooltip
		// so, the tooltip disappears immediately
		// rxjs race "filters" the doubles to the quickest one
		race([this.click$, this.hover$])
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (evt: Event) => {
					if (this.position === 'mouse') {
						const origin = this._elementRef.nativeElement.getBoundingClientRect();
						const e = evt as MouseEvent;
						this.mousey = e.clientY - origin.y;

						const positionStrategy = this._overlayPositionBuilder.flexibleConnectedTo(this._elementRef).withPositions([this.getPosition()]);
						this._overlayRef.updatePositionStrategy(positionStrategy);
					}
					this.show();
				},
			});
	}

	ngOnChanges(changes: SimpleChanges) {
		// if showToolTip is changed from false to true, we need to re-create the tooltip
		if (changes.showToolTip?.currentValue === true && changes.showToolTip?.firstChange === false && changes.showToolTip?.previousValue === false) {
			this.ngOnInit();
			return;
		}

		if (
			(changes.showToolTip && !!changes.showToolTip.currentValue) ||
			(changes.additionalOffsetY && changes.additionalOffsetY?.previousValue !== changes.additionalOffsetY?.currentValue)
		) {
			const positionStrategy = this._overlayPositionBuilder
				.flexibleConnectedTo(this._elementRef)
				.withPositions([this.positionStrategy ? this.positionStrategy : this.getPosition()]);
			// because showToolTip or additionalOffsetY has changed we re-create the tooltip
			// TODO: for other significant attributes, beside showToolTip
			this._overlayRef = this._overlay.create({
				positionStrategy,
				panelClass: 'custom-tooltip',
			});
		}
	}

	getPosition(): ConnectedPosition {
		const basePosition: ConnectedPosition = {
			originX: 'center',
			originY: 'center',
			overlayX: 'center',
			overlayY: 'top',
			offsetX: this.additonalOffsetX || 0,
			offsetY: this.additionalOffsetY || 0,
		};

		switch (this.position) {
			case 'top':
				return {
					...basePosition,
					originY: 'top',
					offsetY: -55 + (basePosition.offsetY ?? 0),
				};
			case 'bottom':
				return {
					...basePosition,
					originY: 'bottom',
					offsetY: 15 + (basePosition.offsetY ?? 0),
				};
			case 'left':
				return {
					...basePosition,
					originY: 'bottom',
					offsetY: 15 + (basePosition.offsetY ?? 0),
					offsetX: -50 + (basePosition.offsetX ?? 0),
				};
			case 'right':
				return {
					...basePosition,
					originY: 'top',
				};
			case 'mouse':
				return {
					...basePosition,
					originY: 'top',
					offsetY: (this.mousey || 0) + 10 + (basePosition.offsetY ?? 0),
				};
			case 'above':
				return {
					...basePosition,
					originY: 'top',
					overlayY: 'bottom',
					offsetY: -10 + (basePosition.offsetY ?? 0),
				};
			default:
				return basePosition;
		}
	}

	@HostListener('mouseleave')
	beginHideTooltip() {
		this.hideTooltipWithDelay();
	}

	click$: Subject<Event> = new Subject<Event>();
	hover$: Subject<Event> = new Subject<Event>();

	@HostListener('click', ['$event'])
	gotClick(evt: Event) {
		this.click$.next(evt);
	}
	@HostListener('pointerover', ['$event'])
	gotHover(evt: Event) {
		this.hover$.next(evt);
	}

	show() {
		if (!(this.text || this.contentTemplate)) {
			return;
		}

		timer(this.toolTipShowDelay)
			.pipe(
				takeUntil(this.destroyOpen$),
				switchMap(() => {
					if (this._overlayRef && !this._overlayRef.hasAttached() && this.showToolTip) {
						this.tooltipRef = this._overlayRef.attach(new ComponentPortal(CustomToolTipComponent));
						if (this.contentTemplate) {
							this.tooltipRef.instance.contentTemplate = this.contentTemplate;
						}
						this.tooltipRef.instance.text = this.text;
						this.tooltipRef.instance.width = this.width;
						this.tooltipRef.instance.nonDisappearing = this.nonDisappearing;
						this.isOpen.emit(true);
						this.tooltipRef.instance.allowVarTag = this.allowVarTag;

						merge(this.tooltipRef.instance.enterTooltip.pipe(map(() => 'enter')), this.tooltipRef.instance.leaveTooltip.pipe(map(() => 'leave')))
							.pipe(takeUntil(this.destroyOpen$))
							.subscribe((eventType) => {
								if (eventType === 'enter') {
									this.cancelHideTooltip();
								} else {
									this.hideTooltipWithDelay();
								}
							});

						return this.tooltipRef.instance.leaveTooltip;
					}
					return of(null);
				})
			)
			.subscribe();
	}

	hideTooltipWithDelay() {
		this.cancelHideTooltip();
		this.hideTooltipTimeout = setTimeout(() => this.closeToolTip(), this.toolTipHideDelay);
	}

	cancelHideTooltip() {
		if (this.hideTooltipTimeout) {
			clearTimeout(this.hideTooltipTimeout);
			this.hideTooltipTimeout = 0;
		}
	}

	private closeToolTip() {
		this.cancelHideTooltip();
		if (this._overlayRef) {
			this._overlayRef.detach();
			this.leave.emit();
			this.isOpen.emit(false);
		}
	}

	ngOnDestroy() {
		this.destroyOpen$.next();
		this.destroyOpen$.complete();
		this.closeToolTip();

		this.destroy$.next();
		this.destroy$.complete();
	}
}
