import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { resizeReportDescription } from '../../../../animations';
import { EncounterPreventionWithOverride, ExclusionGroupWithOverrides } from 'app/services/encounter-prevention.service';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-report-description',
	templateUrl: './report-description.component.html',
	styleUrls: ['./report-description.component.scss'],
	animations: [resizeReportDescription],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DatePipe],
})
export class ReportDescriptionComponent implements OnInit {
	@Input() encounter!: EncounterPreventionWithOverride;
	@Input() group!: ExclusionGroupWithOverrides;
	@Input() collapse = false;

	@ViewChild('wrapper') wrapper!: ElementRef;

	animationTrigger: unknown;
	isOpen = true;
	descs: string[] = [];

	constructor(private cdr: ChangeDetectorRef, private datePipe: DatePipe) {}

	ngOnInit(): void {
		this.buildDescs();
	}

	private buildDescs(): void {
		if (!this.encounter) {
			return;
		}
		this.descs = [];
		if (this.encounter.conflict_pass_id) {
			this.descs = [
				`${this.encounter.conflict_pass_student_name} was going from ${this.encounter.conflict_pass_origin} to ${
					this.encounter.conflict_pass_destination
				} on ${this.datePipe.transform(this.encounter.conflict_pass_start_time, 'shortDate')} from ${this.datePipe.transform(
					this.encounter.conflict_pass_start_time,
					'hh:mm a'
				)} to ${this.datePipe.transform(this.encounter.conflict_pass_end, 'hh:mm a')}.`,
			];
		}

		let concludeMsg = `At ${this.datePipe.transform(this.encounter.pass_time, 'hh:mm a')}, ${this.encounter.issuer_name} attempted to create a pass ${
			this.encounter.issuer_is_staff ? 'for ' + `${this.formatNames(this.encounter.prevented_users)}` : ''
		} from ${this.encounter.origin} to ${this.encounter.destination}, but it was prevented${
			this.encounter.conflict_pass_id ? '' : ' because they are in the same encounter prevention group'
		}.`;

		if (this.encounter?.override) {
			concludeMsg = `At ${this.datePipe.transform(this.encounter.override.override_time, 'hh:mm a')}, ${
				this.encounter.override.overrider_name
			} overrode the Encounter Prevention to create a pass for ${this.formatNames(this.encounter.prevented_users)} from ${this.encounter.origin} to ${
				this.encounter.destination
			}.`;
		}

		this.descs.push(concludeMsg);
		if (this.collapse) {
			this.isOpen = !this.collapse;
		}
	}

	private formatNames(names: string[]): string {
		console.log(names);
		if (names.length === 0) {
			return '';
		}
		if (names.length === 1) {
			return names[0];
		}
		if (names.length === 2) {
			return `${names[0]} and ${names[1]}`;
		}
		// For 3 or more names
		return `${names.slice(0, -1).join(', ')}, and ${names[names.length - 1]}`;
	}

	setStartHeight(): void {
		this.isOpen = !this.isOpen;
		this.animationTrigger = { value: this.isOpen ? 'open' : 'close', params: { startHeight: this.wrapper.nativeElement.clientHeight } };
		this.cdr.detectChanges();
	}
}
