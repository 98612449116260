import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { FlexPeriod, FlexPeriodService } from '../../services/flex-period.service';
import { ToastService } from '../../services/toast.service';
import {
	DeleteFlexPeriodFailureAction,
	DeleteFlexPeriodParams,
	FlexPeriodActionTypes,
	FlexPeriodUpdateParmas,
	GetFlexPeriodsFailureAction,
	GetFlexPeriodsSuccessAction,
	UpdateFlexPeriodFailureAction,
	UpdateFlexPeriodSuccessAction,
} from './flex-periods.actions';

@Injectable()
export class FlexPeriodEffects {
	loadFlexPeriods$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FlexPeriodActionTypes.GetFlexPeriods),
			exhaustMap(() => {
				return this.flexPeriodService.GetAllFlexPeriodsHTTP().pipe(
					map((flexPeriods) => GetFlexPeriodsSuccessAction({ flexPeriods })),
					catchError((error) => of(GetFlexPeriodsFailureAction({ errorMessage: error })))
				);
			})
		);
	});

	updateFlexPeriod$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FlexPeriodActionTypes.UpdateFlexPeriod),
			exhaustMap((action: Action & FlexPeriodUpdateParmas) => {
				const updateBody: Partial<FlexPeriod> = { id: action.update.id, ...action.update.changes };
				return this.flexPeriodService.EditFlexPeriodHTTP(updateBody).pipe(
					map((resp) => {
						return UpdateFlexPeriodSuccessAction({ updated: resp });
					}),
					catchError((_error) => {
						this.toastService.openToast({
							title: `Issue encountered while updating flex period '${action.original?.name}', try again`,
							type: 'error',
						});
						if (action.original) {
							return of(UpdateFlexPeriodFailureAction({ original: action.original }));
						} else {
							return of(null);
						}
					})
				);
			})
		);
	});

	deleteFlexPeriod$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FlexPeriodActionTypes.DeleteFlexPeriod),
			exhaustMap((action: Action & DeleteFlexPeriodParams) => {
				return this.flexPeriodService.DeleteFlexPeriodHTTP(action.id).pipe(
					map(() => {
						this.toastService.openToast({ title: `Flex period '${action.toRemove?.name}' removed`, type: 'success' });
						return null;
					}),
					catchError((_error) => {
						this.toastService.openToast({
							title: `Issue encountered while deleting flex period '${action.toRemove?.name}', try again`,
							type: 'error',
						});
						if (action.toRemove) {
							return of(DeleteFlexPeriodFailureAction({ flexPeriod: action.toRemove }));
						} else {
							return of(null);
						}
					})
				);
			})
		);
	});

	constructor(private actions$: Actions, private flexPeriodService: FlexPeriodService, private toastService: ToastService) {}
}
