import * as React from 'react';
import { SchoolActivity } from 'app/services/school-activity.service';
import { ActivityWithInstance } from 'app/react-components/hooks/use-activity-instance';
import { ActivityBox } from './activity-box';

interface IActivityBoxesProps {
	isLoading: boolean;
	activityInstances: ActivityWithInstance[];
	openActivityDialog: (activity: SchoolActivity) => void;
}

export function ActivityBoxes({ isLoading, activityInstances, openActivityDialog }: IActivityBoxesProps): React.ReactElement {
	const textClass =
		'tw-animate-fadein-300 tw-text-base tw-text-gray-400 tw-font-medium tw-text-center tw-flex tw-flex-col tw-justify-center tw-w-full tw-flex-grow tw-h-40';

	return isLoading ? (
		<div className={textClass}>Loading...</div>
	) : activityInstances.length > 0 ? (
		<div className="tw-grid tw-grid-cols-4 tw-gap-7 tw-mt-5">
			{activityInstances.map((ai) => (
				<ActivityBox
					key={ai.activity.id + ai.instance.id}
					name={ai.activity.name}
					teacher={ai.activity.teacher_name}
					teachers={ai.activity.managers}
					numStudents={ai.instance.current_num_attendees || 0}
					onClick={() => openActivityDialog(ai.activity)}
					maxCapacity={ai.activity.max_attendees}
				/>
			))}
		</div>
	) : (
		<div className={textClass}>No Activities Scheduled</div>
	);
}
