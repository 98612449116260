import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { zonedTimeToUtc } from 'date-fns-tz';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, merge, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { Location, Pinnable, User } from '../models';
import { ColorProfile } from '../models/ColorProfile';
import { AppState } from '../ngrx/app-state/app-state';
import {
	AddSchoolActivityInstanceAction,
	ClearSelectedActivityInstanceAction,
	CreateSchoolActivityInstanceAction,
	CreateSchoolActivityInstancesBulkAction,
	CreateSchoolActivityInstancesBulkSuccessAction,
	GetSchoolActivityInstancesAction,
	GetSchoolActivityInstancesForFlexPeriodAction,
	RemoveAllSchoolActivityInstancesAction,
	RemoveSchoolActivityInstanceAction,
	RemoveSchoolActivityInstanceSuccessAction,
	SelectActivityInstanceAction,
	UpdateSchoolActivityInstanceSuccessAction,
} from '../ngrx/school-activities-instances/actions/school-activities-instances.actions';
import {
	getActivityInstancesCollection,
	getPastSchoolActivityInstances,
	getSchoolActivityInstanceById,
	getSelectedActivityInstance,
	getUpcomingSchoolActivityInstances,
} from '../ngrx/school-activities-instances/states/school-activities-instances-getters.state';
import {
	AddSchoolActivityAction,
	AddSchoolActivitySuccessAction,
	DeleteSchoolActivityAction,
	DeleteSchoolActivitySuccessAction,
	GetSchoolActivitiesAction,
	GetSchoolActivitiesByStatusAction,
	GetSchoolActivityByIdAction,
	UpdateSchoolActivityAction,
	UpdateSchoolActivitySuccessAction,
} from '../ngrx/school-activities/actions/school-activities.actions';
import { getSchoolActivityById } from '../ngrx/school-activities/states/school-activities-getters.state';
import {
	BulkSignOutForActivityAction,
	BulkSignUpForActivityAction,
	GetAttendeesForInstanceAction,
	RemoveAttendeeAndSignUpAction,
	RemoveAttendeesAction,
	SignUpForActivityAction,
	SignUpForActivitySuccessAction,
} from '../ngrx/school-activity-attendees/actions/school-activity-attendees.actions';
import { getAttendeeByUserId } from '../ngrx/school-activity-attendees/states/school-activity-attendees-getters.state';
import { FlexPeriod } from './flex-period.service';
import { HttpService } from './http-service';
import { PollingService } from './polling-service';

export type SchoolActivityState = 'flex_recurring' | 'scheduled' | 'canceled' | 'deleted' | 'archived';
export type SchoolActivityStatus = 'active' | 'archived';
export type SchoolActivityInstanceState = 'scheduled' | 'canceled';

export interface CreateSchoolActivityReq {
	name: string;
	icon: string;
	description: string;
	location_id: number;
	max_attendees: number;
	public_event: boolean;
	flex_period_id: number;
	state: SchoolActivityState;
	status: SchoolActivityStatus;
	manager_ids?: number[];
}
export interface CreateSchoolActivityInstanceReq {
	start: Date;
	end: Date;
	activityId: number;
	state: SchoolActivityState;
	selected: boolean;
}

export interface GetAttendeesForInstanceReq {
	activity_instance_id: number;
	start_time: string;
	include_user_profiles: boolean;
}

export interface SignUpForActivityReq {
	user_id: number;
	activity_id: number;
	scheduled_date: Date;
	activity_instance_id: number;
}
export interface BulkSignUpForActivityReq {
	user_ids: number[];
	activity_id: number;
	activity_instance_id: number;
	scheduled_date?: Date;
}

export interface UpdateSchoolActivityRequest extends CreateSchoolActivityReq {
	id: number;
}

export interface SchoolActivity {
	id: number;
	icon: string;
	name: string;
	description: string;
	user_id: number;
	location_id: number;
	color_profile?: ColorProfile;
	flex_period_id: number;
	max_attendees?: number;
	public_event: boolean;
	teacher_name?: string;
	profile_picture?: string;
	created_at?: string;
	updated_at?: string;
	deleted_at?: string;
	state: SchoolActivityState;
	status: SchoolActivityStatus;
	managers: User[];
}

export interface SchoolActivityRow extends SchoolActivity {
	location_icon: string;
	location_name: string;
	location?: Location;
	flex_period_name: string;
}

export interface SchoolActivityInstance {
	id: number;
	start_time: string;
	end_time: string;
	activity_id: number;
	user_id: number;
	school_id: number;
	created_at: string;
	updated_at: string;
	current_num_attendees?: number;
	state: SchoolActivityInstanceState;
	color_profile?: ColorProfile;
	selected?: boolean;
	flex_period_id?: number;
	flex_period_name?: string;
	activity_name?: string;
	deleted_at?: string;
}
export interface SchoolActivityInstancesReq {
	from: Date;
	to: Date;
	activity: SchoolActivity;
	timezone: string;
	flexPeriod?: FlexPeriod;
}

export interface SchoolActivityInstancesForPeriodReq {
	day: Date;
	activities: SchoolActivity[];
	flexPeriod: FlexPeriod;
	timezone: string;
}
export interface SchoolActivityAttendee {
	id: number;
	activity_id: number;
	activity_instance_id: number;
	activity_name: string;
	flex_period_id: number;
	user_id: number;
	pass_id: number;
	created_at: string;
	updated_at: string;
	state: string;
	school_id: number;
	assigner_id: number;
	start_time?: Date;
	user?: User;
}
export interface AttendeeData {
	school_activity_id: number;
	school_activity_instance_id: number;
	start_time: string;
	end_time: string;
	user_ids: number[];
}
export type GetActivitiesReq = {
	start_time: Date;
	end_time: Date;
	activity_id?: number;
};
export type MaxCapacityValues = {
	errorMessage: string;
	maxCapacityString: string;
	maxCapacityNumber?: number;
};

export interface InstanceTimes {
	start: Date;
	end: Date;
}
export const DAYS_SHOW_ACTIVITY_FUTURE_TEACHER = 90;
@Injectable({
	providedIn: 'root',
})
export class SchoolActivityService {
	hasSearchResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	selectedStudents$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
	attendeeIdsToDelete$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
	refreshingInstances$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	attendees$: Observable<SchoolActivityAttendee[]> = this.store.select('schoolActivityAttendees').pipe(
		map((state) => Object.values(state.entities).filter((attendee): attendee is SchoolActivityAttendee => attendee !== undefined)),
		map((at) => {
			return at.sort((a, b) => {
				if (a.user?.display_name && b.user?.display_name) {
					return a.user.display_name.localeCompare(b.user.display_name);
				}
				return 0;
			});
		})
	);

	attendeesCount$: Observable<number> = this.store.select('schoolActivityAttendees').pipe(map((state) => Object.values(state.entities).length));

	activities$ = this.store.select('schoolActivities');
	activity$ = (id: number) => this.store.pipe(select(getSchoolActivityById(id)));

	pastInstances$ = (currentDate: Date) =>
		this.store.pipe(
			distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
			select(getPastSchoolActivityInstances(currentDate)),
			map((instances) => {
				return instances?.sort((a, b) => new Date(a!.start_time).getTime() - new Date(b!.start_time).getTime()) || [];
			})
		);

	upcomingInstances$ = (currentDate: Date) =>
		this.store.pipe(
			distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
			select(getUpcomingSchoolActivityInstances(currentDate)),
			map((instances) => {
				return instances?.sort((a, b) => new Date(a!.start_time).getTime() - new Date(b!.start_time).getTime()) || [];
			})
		);

	attendeeByUserId$ = (userId: number) => this.store.pipe(select(getAttendeeByUserId(userId)));

	instances$: Observable<SchoolActivityInstance[]> = this.store.pipe(select(getActivityInstancesCollection));

	instancesLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	selectedInstance$ = this.store.pipe(select(getSelectedActivityInstance));
	instanceTimes$: BehaviorSubject<InstanceTimes[]> = new BehaviorSubject<InstanceTimes[]>([]);
	constructor(private http: HttpService, private store: Store<AppState>, private polling: PollingService) {}

	CreateActivity(schoolActivity: CreateSchoolActivityReq, instanceTimes: InstanceTimes[]): void {
		this.store.dispatch(AddSchoolActivityAction({ schoolActivity: schoolActivity, instanceTimes: instanceTimes }));
	}

	CreateActivityHTTP(body: CreateSchoolActivityReq): Observable<SchoolActivity> {
		return this.http.post<SchoolActivity>('v2/school_activities/add', body, undefined, false);
	}

	DeleteActivity(id: number): void {
		this.store.dispatch(DeleteSchoolActivityAction({ activityId: id }));
	}

	DeleteActivityHTTP(id: number): Observable<Record<string, never>> {
		return this.http.post<Record<string, never>>('v2/school_activities/delete', { id: id }, undefined, false);
	}

	RemoveAttendeeFromActivityInstanceAndSignUp(attendeeId: number, req?: SignUpForActivityReq, instance?: SchoolActivityInstance): void {
		this.store.dispatch(RemoveAttendeeAndSignUpAction({ attendeeId, req, instance }));
	}

	RemoveAttendeeFromActivityInstanceHTTP(antendeeRecordId: number): Observable<null> {
		return this.http.post<null>('v2/school_activities/attendee/delete', { id: antendeeRecordId }, undefined, false);
	}

	GetActivities(flexPeriodId?: number): void {
		this.store.dispatch(GetSchoolActivitiesAction({ flex_period_id: flexPeriodId }));
	}

	GetActivitiesHTTP(flexPeriodId?: number): Observable<SchoolActivity[]> {
		return this.http.post<SchoolActivity[]>('v2/school_activities/list', { flex_period_id: flexPeriodId }, undefined, false);
	}

	GetActivitiesByStatusHTTP(status: SchoolActivityStatus, flexPeriodId?: number, publicEvent?: boolean): Observable<SchoolActivity[]> {
		return this.http.post<SchoolActivity[]>(
			'v2/school_activities/list',
			{
				flex_period_id: flexPeriodId,
				status: status,
				public_event: publicEvent,
			},
			undefined,
			false
		);
	}

	GetActivitiesByStatus(status: SchoolActivityStatus): void {
		this.store.dispatch(
			GetSchoolActivitiesByStatusAction({
				status: status,
			})
		);
	}

	GetActivityById(schoolActivityId: number): void {
		this.store.dispatch(GetSchoolActivityByIdAction({ activityId: schoolActivityId }));
	}

	GetActivityByIdHTTP(schoolActivityId: number): Observable<SchoolActivity> {
		return this.http.post<SchoolActivity>('v2/school_activities/get_by_id', { school_activity_id: schoolActivityId }, undefined, false);
	}

	SignUpForActivity(req: SignUpForActivityReq, instance: SchoolActivityInstance): void {
		this.store.dispatch(SignUpForActivityAction({ req, instance }));
	}

	SignUpForActivityHTTP(req: SignUpForActivityReq): Observable<SchoolActivityAttendee> {
		return this.http.post<SchoolActivityAttendee>('v2/school_activities/attendee/add', req, undefined, false);
	}

	BulkSignUpForActivity(req: BulkSignUpForActivityReq, instance: SchoolActivityInstance): void {
		this.store.dispatch(BulkSignUpForActivityAction({ req, instance }));
		if (instance.current_num_attendees !== undefined) {
			instance.current_num_attendees = instance.current_num_attendees + req.user_ids.length;
		} else if (instance.current_num_attendees === undefined) {
			instance.current_num_attendees = req.user_ids.length;
		}
		this.UpdateActivityInstance(instance, instance.id, instance.id);
	}

	BulkSignUpForActivityHTTP(req: BulkSignUpForActivityReq, instance: SchoolActivityInstance): Observable<SchoolActivityAttendee[]> {
		return this.http.post<SchoolActivityAttendee[]>('v2/school_activities/attendee/bulk_add', req, undefined, false).pipe(
			map((resp) => {
				// if students were signing up for a "fake" instance, we need to update the instance id in the store
				if (instance.id < 0) {
					const oldId = instance.id;
					this.UpdateActivityInstance(instance, oldId, resp[0].activity_instance_id);
				}
				return resp;
			})
		);
	}

	BulkSignOutForActivity(attendeeIds: number[], instance: SchoolActivityInstance): void {
		this.store.dispatch(BulkSignOutForActivityAction({ attendeeIds: attendeeIds }));
		if (instance.current_num_attendees !== undefined) {
			instance.current_num_attendees = instance.current_num_attendees - attendeeIds.length;
		} else if (instance.current_num_attendees === undefined) {
			instance.current_num_attendees = 0;
		}
		this.UpdateActivityInstance(instance, instance.id, instance.id);
	}

	BulkSignOutForActivityHTTP(attendeeIds: number[]): Observable<SchoolActivityAttendee[]> {
		return this.http.post<SchoolActivityAttendee[]>('v2/school_activities/attendee/bulk_delete', { attendee_ids: attendeeIds }, undefined, false);
	}

	UpdateActivityInstance(instance: SchoolActivityInstance, oldId: number, newId: number): void {
		// todo this needs to be tested from all uses of it
		this.DeleteActivityInstanceFromStore(oldId);
		instance.id = newId;
		this.store.dispatch(AddSchoolActivityInstanceAction({ instance }));
		this.SelectActivityInstance(newId);
	}

	CreateActivityInstance(start: Date, end: Date, activityId: number, state: SchoolActivityState): void {
		this.store.dispatch(CreateSchoolActivityInstanceAction({ start: start, end: end, activityId: activityId, state: state }));
	}

	CreateActivityInstanceBulk(instanceReqs: CreateSchoolActivityInstanceReq[], removeFakeInstances: boolean): void {
		this.store.dispatch(CreateSchoolActivityInstancesBulkAction({ instanceReqs, removeFakeInstances }));
	}

	CreateActivityInstanceHTTP(
		start: Date,
		end: Date,
		activityId: number,
		state: SchoolActivityInstanceState = 'scheduled'
	): Observable<SchoolActivityInstance> {
		return this.http.post<SchoolActivityInstance>(
			'v2/school_activities/instances/add',
			{ start_time: start.toISOString(), end_time: end.toISOString(), activity_id: activityId, state: state },
			undefined,
			false
		);
	}

	UpdateActivityHTTP(activity: Partial<SchoolActivity>): Observable<SchoolActivity> {
		return this.http.post<SchoolActivity>('v2/school_activities/update', activity, undefined, false);
	}

	UpdateActivity(activityReq: Partial<UpdateSchoolActivityRequest>): void {
		this.store.dispatch(UpdateSchoolActivityAction({ activity: activityReq }));
	}

	UpdateActivityInstanceHTTP(instance: Partial<SchoolActivityInstance>): Observable<SchoolActivityInstance> {
		return this.http.post<SchoolActivityInstance>('v2/school_activities/instances/update', instance, undefined, false);
	}

	DeleteActivityInstance(id: number): void {
		this.store.dispatch(RemoveSchoolActivityInstanceAction({ instanceId: id }));
	}

	DeleteActivityInstanceFromStore(id: number): void {
		this.store.dispatch(RemoveSchoolActivityInstanceSuccessAction({ instanceId: id }));
	}

	DeleteActivityInstanceHTTP(id: number): Observable<Record<string, never>> {
		return this.http.post<Record<string, never>>('v2/school_activities/instances/delete', { id: id }, undefined, false);
	}

	GetAttendeeRecordForStudent(activity_instance_id: number, studentId: number, start_time: Date): Observable<SchoolActivityAttendee | null> {
		return this.http
			.post<SchoolActivityAttendee[]>(
				'v2/school_activities/attendee/list',
				{ activity_instance_id: activity_instance_id, user_id: studentId, start_time: start_time.toISOString() },
				undefined,
				false
			)
			.pipe(map((l) => (l.length > 0 ? l[0] : null)));
	}

	GetAttendeeRecordsForTimePeriod(from: Date, to: Date): Observable<SchoolActivityAttendee[]> {
		return this.http.post<SchoolActivityAttendee[]>(
			'v2/school_activities/attendee/list',
			{ start_time: from.toISOString(), end_time: to.toISOString() },
			undefined,
			false
		);
	}

	GetActivityInstanceById(id: number): Observable<SchoolActivityInstance> {
		return this.http.post<SchoolActivityInstance>('v2/school_activities/instances/get_by_id', { id: id }, undefined, false);
	}

	GetActivityInstancesHTTP(req: GetActivitiesReq): Observable<SchoolActivityInstance[]> {
		return this.http.post<SchoolActivityInstance[]>('v2/school_activities/instances/list', req, undefined, false);
	}

	GetStartedActivityInstances(locationId: number, bufferMinutes: number): Observable<SchoolActivityInstance[]> {
		return this.http.post<SchoolActivityInstance[]>(
			'v2/school_activities/instances/location/list',
			{ location_id: locationId, buffer: bufferMinutes, include_past_instances: false },
			undefined,
			false
		);
	}

	GetAttendeesForInstance(req: GetAttendeesForInstanceReq): void {
		this.store.dispatch(GetAttendeesForInstanceAction({ req: req }));
	}

	GetAttendeesForInstanceHTTP(req: GetAttendeesForInstanceReq): Observable<SchoolActivityAttendee[]> {
		return this.http.post<SchoolActivityAttendee[]>('v2/school_activities/attendee/list', req, undefined, false);
	}

	ClearActivityAttendeesStore(): void {
		this.store.dispatch(RemoveAttendeesAction());
	}

	ClearActivityInstancesStore(): void {
		this.store.dispatch(RemoveAllSchoolActivityInstancesAction());
	}

	/**
	 * returns a beginning date object and an end date object for a specified date and timezone
	 * @param date
	 * @private
	 */
	getBeginningAndEndDates(date: Date, timezone: string): { start: Date; end: Date } {
		// JavaScript treat its "Date" type as a string as well, so we need to be sure
		if (!(date instanceof Date)) {
			date = new Date(date);
		}

		date = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
		return {
			start: moment(date).startOf('day').toDate(),
			end: moment(date).endOf('day').toDate(),
		};
	}

	GetActivityInstancesByPeriodAndFillExtra(data: SchoolActivityInstancesForPeriodReq): void {
		this.store.dispatch(GetSchoolActivityInstancesForFlexPeriodAction({ req: data }));
	}

	GetActivityInstancesByPeriodAndFillExtraHTTP(data: SchoolActivityInstancesForPeriodReq): Observable<SchoolActivityInstance[]> {
		const school = this.http.getSchool();
		let beginningOfDay: Date;
		let endOfDay: Date;

		try {
			const { start, end } = this.getBeginningAndEndDates(data.day, school.timezone);
			beginningOfDay = start;
			endOfDay = end;
		} catch (e) {
			console.error(e);
			return throwError(e);
		}
		const startDateTime = new Date(data.day);
		const endDateTime = new Date(data.day);
		if (data?.flexPeriod?.schedules) {
			data.flexPeriod.schedules.forEach((schedule) => {
				if (schedule.days_of_week.find((day) => day === startDateTime.getDay())) {
					startDateTime.setHours(schedule.start_hour, schedule.start_minute);
					endDateTime.setHours(schedule.end_hour, schedule.end_minute);
				}
			});
		}

		const filteredActivities = data.activities.filter((activity) => activity.flex_period_id === data.flexPeriod?.id);

		return this.http
			.post<SchoolActivityInstance[]>(
				'v2/school_activities/instances/list',
				{ start_time: beginningOfDay.toISOString(), end_time: endOfDay.toISOString() },
				undefined,
				false
			)
			.pipe(
				map((instances) => {
					let studentInstances: SchoolActivityInstance[] = [];

					let fakeInstanceIndex = 0;
					filteredActivities.forEach((activity) => {
						const foundInstance = instances.find((instance) => instance.activity_id === activity.id);
						if (foundInstance) {
							studentInstances.push(foundInstance);
						} else if (activity.state === 'flex_recurring') {
							fakeInstanceIndex += 1;
							const newInstance: SchoolActivityInstance = {
								id: -fakeInstanceIndex,
								start_time: this.utcDateTimeZone(startDateTime, data.timezone).toISOString(),
								end_time: this.utcDateTimeZone(endDateTime, data.timezone).toISOString(),
								activity_id: activity.id || 0,
								user_id: 0,
								school_id: 0,
								created_at: new Date().toDateString(),
								updated_at: new Date().toDateString(),
								state: 'scheduled',
								activity_name: activity.name,
								flex_period_id: activity.flex_period_id,
								color_profile: activity.color_profile,
								current_num_attendees: 0,
							};

							studentInstances.push(newInstance);
						}
					});

					studentInstances = studentInstances.filter((instance) => instance.state !== 'canceled');
					this.instancesLoaded$.next(true);
					return studentInstances;
				})
			);
	}

	GetActivityInstancesByIdAndFillExtra(data: SchoolActivityInstancesReq): void {
		this.store.dispatch(GetSchoolActivityInstancesAction({ req: data }));
	}

	GetActivityInstancesByIdAndFillExtraHTTP(data: SchoolActivityInstancesReq): Observable<SchoolActivityInstance[]> {
		// update the date parsing logic here too
		return this.http
			.post<SchoolActivityInstance[]>(
				'v2/school_activities/instances/list',
				{ start_time: data.from.toISOString(), end_time: data.to.toISOString(), activity_id: data.activity?.id },
				undefined,
				false
			)
			.pipe(
				map((instances) => {
					if (data.activity?.state !== 'flex_recurring') {
						return instances.sort((a, b) => {
							const startTimeA = new Date(a.start_time).getTime();
							const startTimeB = new Date(b.start_time).getTime();
							return startTimeA - startTimeB;
						});
					}

					const filledInstances: SchoolActivityInstance[] = [];

					// Iterate through each day in the range
					const currentDate = new Date(data.from);
					let index = 0;
					while (currentDate <= data.to) {
						const dayOfWeek = currentDate.getDay();
						const matchingSchedules = data.flexPeriod?.schedules?.filter((schedule) => schedule.days_of_week.includes(dayOfWeek));

						// Add instances for each matching schedule
						if (matchingSchedules && matchingSchedules.length > 0) {
							matchingSchedules.forEach((schedule) => {
								const startDateTime = new Date(currentDate);
								startDateTime.setHours(schedule.start_hour, schedule.start_minute, 0, 0);

								const endDateTime = new Date(currentDate);
								endDateTime.setHours(schedule.end_hour, schedule.end_minute, 0, 0);

								const existingInstance = instances.find((instance) => {
									const instanceStart = new Date(instance.start_time);
									return instance.activity_id === data.activity.id && instanceStart.toDateString() === startDateTime.toDateString();
								});
								// Add instance only if it doesn't already exist for the day
								if (!existingInstance) {
									const newInstance: SchoolActivityInstance = {
										// these need to be unique because they are used as keys in ngrx store
										// making them a negative number means they won't conflict with existing instances
										id: -index - 1,
										start_time: this.utcDateTimeZone(startDateTime, data.timezone).toISOString(),
										end_time: this.utcDateTimeZone(endDateTime, data.timezone).toISOString(),
										activity_id: data.activity.id || 0,
										user_id: 0,
										school_id: 0,
										created_at: new Date().toDateString(),
										updated_at: new Date().toDateString(),
										state: 'scheduled',
										current_num_attendees: 0,
									};

									filledInstances.push(newInstance);
								}
							});
							index++;
						}

						// Move to the next day
						currentDate.setDate(currentDate.getDate() + 1);
					}

					// Remove cancelled instances
					instances = instances.filter((instance) => instance.state !== 'canceled');

					// Combine existing and newly created instances
					const allInstances = [...instances, ...filledInstances];

					// Sort instances by start_time
					allInstances.sort((a, b) => {
						const startTimeA = new Date(a.start_time).getTime();
						const startTimeB = new Date(b.start_time).getTime();
						return startTimeA - startTimeB;
					});
					return allInstances;
				})
			);
	}

	private utcDateTimeZone(time: Date, zone: string): Date {
		if (zone) {
			return zonedTimeToUtc(time, zone);
		}
		return time;
	}

	handleMaxCapacity(isFocused: boolean, fieldValue: string | number | undefined): MaxCapacityValues {
		const numberValue = fieldValue ? parseInt(fieldValue.toString()) : null;
		if (isFocused) {
			const values: MaxCapacityValues = { errorMessage: '', maxCapacityString: '' };
			if (numberValue) {
				values.maxCapacityNumber = numberValue;
			}
			return values;
		} else {
			if (fieldValue === '0' || fieldValue === 0) {
				return { errorMessage: 'Please add a max capacity greater than “0”.', maxCapacityString: '' };
			}
			if (fieldValue === undefined || fieldValue === '') {
				return { errorMessage: 'Please add a max capacity for this activity.', maxCapacityString: '' };
			}
			return {
				errorMessage: '',
				maxCapacityString: `${numberValue} student${numberValue !== 1 ? 's' : ''}`,
			};
		}
	}

	parseSchoolActivity(activity: SchoolActivity, locs: Location[], pins: Pinnable[], flexPeriods: FlexPeriod[]): SchoolActivityRow {
		const loc = locs.find((location) => location.id === activity.location_id);
		const pin = pins.find((p) => (loc?.category && p?.category == loc.category) || p?.location?.id === loc?.id);
		activity.color_profile = pin?.color_profile;
		const flexPeriodName = flexPeriods.find((flexPeriod) => flexPeriod.id === activity.flex_period_id)?.name;
		return {
			...activity,
			location_icon: pin?.icon || '',
			location_name: loc?.title || '',
			location: loc ? loc : undefined,
			flex_period_name: flexPeriodName || '',
		};
	}

	getTeacherNames(activity: Partial<SchoolActivity>): string {
		if (activity.managers && activity.managers.length > 0) {
			return activity.managers.map((m) => m.display_name).join(', ');
		}
		// NOTE: teacher_name is a fallback to allow legacy activities to show correct titles
		if (activity.teacher_name) {
			return activity.teacher_name;
		}
		return '';
	}

	private createActivityPipeForTeacher(userId: number) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				filter((event) => {
					const activity = event.data;
					if (Array.isArray(activity)) {
						return (
							activity.filter((p) => {
								const managerIds = p.managers.map((m: User) => m.id);
								return p.user_id === userId || managerIds.includes(userId);
							}).length > 0
						);
					} else {
						const managerIds = activity.managers.map((m: User) => m.id);
						return activity.user_id === userId || managerIds.includes(userId);
					}
				}),
				catchError((e, originalObs) => {
					console.log('Error in createActivityPipeForTeacher', e);
					return originalObs;
				})
			);
	}

	watchActivityEventsForTeacher(userId: number): Observable<any> {
		const activityPipe = this.createActivityPipeForTeacher(userId);
		return this.polling.isConnected$.pipe(
			filter(Boolean),
			distinctUntilChanged(),
			switchMap(() => {
				return merge(
					this.polling.listen('school_activity.create').pipe(activityPipe),
					this.polling.listen('school_activity.update').pipe(activityPipe),
					this.polling.listen('school_activity.delete').pipe(activityPipe)
				);
			}),
			map((event) => {
				if (!event) {
					console.log('event is null');
					return;
				}
				if (Array.isArray(event)) {
					for (const item of event) {
						this.handleEvent(item.action, item.data);
					}
				} else {
					this.handleEvent(event.action, event.data);
				}
			})
		);
	}

	watchActivityInstanceEventsForTeacher(): Observable<any> {
		return this.polling.isConnected$.pipe(
			filter(Boolean),
			distinctUntilChanged(),
			switchMap(() => {
				return merge(
					this.polling.listen('school_activity_instances.create'),
					this.polling.listen('school_activity_instances.update'),
					this.polling.listen('school_activity_instances.delete')
				);
			}),
			switchMap((event) => {
				let activityId = 0;
				if (event.data) {
					const inst = Array.isArray(event.data) ? event.data[0] : event.data;
					if (this.isSchoolActivityInstance(inst)) {
						activityId = inst.activity_id;
					}
				}
				return combineLatest([this.store.pipe(select(getSchoolActivityById(activityId))), this.instances$, of(event)]);
			}),
			distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
			filter(([activityInStore, instances, event]) => {
				return !!activityInStore && !!instances && !!event;
			}),
			map(([activityInStore, instances, event]) => {
				if (activityInStore!.id !== instances[0]?.activity_id) {
					return;
				}
				if (!event) {
					console.log('event is null');
					return;
				}
				if (Array.isArray(event)) {
					for (const item of event) {
						this.handleEvent(item.action, item.data);
					}
				} else {
					this.handleEvent(event.action, event.data);
				}
			})
		);
	}

	watchActivityAttendeeEventsForTeacher(): Observable<any> {
		return this.polling.isConnected$.pipe(
			filter(Boolean),
			distinctUntilChanged(),
			switchMap(() => {
				return merge(
					this.polling.listen('school_activity_attendee.create'),
					this.polling.listen('school_activity_attendee.update'),
					this.polling.listen('school_activity_attendee.delete')
				);
			}),
			switchMap((event) => {
				let instanceId = 0;
				if (event.data && Array.isArray(event.data) && this.isSchoolActivityAttendee(event.data[0])) {
					instanceId = event.data[0].activity_instance_id!;
				}
				return combineLatest([this.store.pipe(select(getSchoolActivityInstanceById(instanceId))), of(event)]);
			}),
			distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
			filter(([instanceInStore, event]) => {
				return !!instanceInStore && !!event;
			}),
			map(([instanceInStore, event]) => {
				if (!instanceInStore) {
					return;
				}
				if (Array.isArray(event)) {
					for (const item of event) {
						this.handleEvent(item.action, item.data);
					}
				} else {
					this.handleEvent(event.action, event.data);
				}
			})
		);
	}

	private isSchoolActivityInstance(data: any): data is SchoolActivityInstance {
		return (data as SchoolActivityInstance).activity_id !== undefined;
	}
	private isSchoolActivityAttendee(data: any): data is SchoolActivityAttendee {
		return (data as SchoolActivityAttendee).activity_instance_id !== undefined;
	}

	private handleEvent(action: string, data: any) {
		switch (action) {
			case 'school_activity.create':
				this.store.dispatch(AddSchoolActivitySuccessAction({ schoolActivity: data[0] }));
				break;
			case 'school_activity.update':
				this.store.dispatch(UpdateSchoolActivitySuccessAction({ schoolActivity: data }));
				break;
			case 'school_activity.delete':
				this.store.dispatch(DeleteSchoolActivitySuccessAction({ activityId: data.id }));
				break;
			case 'school_activity_instances.create':
				// In the case of an activity being switched from 'flex_recurring' to 'scheduled', we need to remove the fake instances from the store.
				// This is safe to do even if the schedule type was already 'scheduled', as the store will just ignore the action if the instance already exists.
				if (data[0].state === 'scheduled') {
					this.store.dispatch(
						CreateSchoolActivityInstancesBulkSuccessAction({ instance: data[0], selectInstance: false, removeFakeInstances: true })
					);
					return;
				}
				this.store.dispatch(AddSchoolActivityInstanceAction({ instance: data[0] }));
				break;
			case 'school_activity_instances.update':
				this.store.dispatch(UpdateSchoolActivityInstanceSuccessAction({ instance: data }));
				break;
			case 'school_activity_instances.delete':
				this.store.dispatch(RemoveSchoolActivityInstanceSuccessAction({ instanceId: data.id }));
				break;
			case 'school_activity_attendee.create':
				this.store.dispatch(SignUpForActivitySuccessAction({ attendee: data[0] }));
				break;
			case 'school_activity_attendee.delete':
				this.store.dispatch(RemoveAttendeeAndSignUpAction({ attendeeId: data[0].id }));
				break;
		}
	}

	WatchActivityEventsForStudent(): Observable<any> {
		return this.polling.isConnected$.pipe(
			filter(Boolean),
			distinctUntilChanged(),
			switchMap(() => {
				return merge(
					this.polling.listen('school_activity.create'),
					this.polling.listen('school_activity.update'),
					this.polling.listen('school_activity.delete'),
					this.polling.listen('school_activity_instances.create'),
					this.polling.listen('school_activity_instances.update'),
					this.polling.listen('school_activity_instances.delete')
				);
			}),
			map((event) => {
				if (!event) {
					console.log('event is null');
					return;
				}
				if (Array.isArray(event)) {
					for (const item of event) {
						this.handleEvent(item.action, item.data);
					}
				} else {
					this.handleEvent(event.action, event.data);
				}
			})
		);
	}

	ClearSelectedActivityInstance(): void {
		this.store.dispatch(ClearSelectedActivityInstanceAction());
	}
	SelectActivityInstance(instanceId: number): void {
		this.store.dispatch(SelectActivityInstanceAction({ instanceId: instanceId }));
	}
}
