<div class="tw-pt-7 tw-border-box group-wrapper" #wrapper>
	<ng-container *ngIf="activity.status === 'active'">
		<mat-tab-group
			class="mat-tab-group activities-sessions"
			id="activity-sessions-tab-group"
			disableRipple
			disablePagination
			[@.disabled]="true"
			[selectedIndex]="selectedTabIndex"
			(selectedIndexChange)="onTabSelect($event)">
			<mat-tab>
				<ng-template mat-tab-label>
					<div class="tw-animate-fadein-200 tab-label-wrapper tw-text-center right-border">
						<div class="tab-label">Upcoming</div>
					</div>
				</ng-template>
				<!-- Upcoming MAT TAB CONTENT -->
				<ng-container>
					<ng-container [ngTemplateOutlet]="instancesContent" [ngTemplateOutletContext]="{ instances: upcomingInstances }"></ng-container>
				</ng-container>
				<!-- END Upcoming MAT TAB CONTENT -->
			</mat-tab>
			<mat-tab>
				<ng-template mat-tab-label>
					<div class="tw-animate-fadein-200 tab-label-wrapper tw-text-center right-border">
						<div class="tab-label">Past</div>
					</div>
				</ng-template>
				<!-- Past MAT TAB CONTENT -->
				<ng-container>
					<ng-container [ngTemplateOutlet]="instancesContent" [ngTemplateOutletContext]="{ instances: pastInstances }"></ng-container>
				</ng-container>
				<!-- END Past MAT TAB CONTENT -->
			</mat-tab>
		</mat-tab-group>
	</ng-container>

	<!-- ARCHIVED ACTIVITIES ONLY DISPLAY PAST INSTANCES -->
	<ng-container *ngIf="activity.status === 'archived'">
		<ng-container [ngTemplateOutlet]="instancesContent" [ngTemplateOutletContext]="{ instances: pastInstances }"></ng-container>
	</ng-container>
	<ng-template #instancesContent let-instances="instances">
		<div [ngStyle]="{ height: contentHeightStr }" class="tw-relative">
			<div class="sessions-button tw-pb-4" *ngIf="sessionsEditable">
				<app-gradient-button
					style="height: 59px"
					[disabled]="activity.state === 'flex_recurring'"
					[fontSize]="'16px'"
					[leftImageWidth]="'16px'"
					[leftImageHeight]="'16px'"
					[size]="'editable'"
					[width]="'100%'"
					[padding]="'20px'"
					[minWidth]="'100%'"
					[leftIcon]="'./assets/Plus (Gray-500).svg'"
					[withShadow]="false"
					[customBackground]="'#F5F6F8'"
					[textColor]="'#7083A0'"
					(buttonClick)="openSelectDaysModal()"
					[showToolTip]="activity.state === 'flex_recurring'"
					customToolTip
					[position]="'bottom'"
					[additionalOffsetY]="-28"
					[contentTemplate]="disabledTooltip">
					New Session</app-gradient-button
				>
				<ng-template #disabledTooltip>
					<div style="width: 206px">
						<sp-tooltip [text]="tooltipText ? tooltipText : ''"></sp-tooltip>
					</div>
				</ng-template>
			</div>
			<div
				*ngIf="!loading && instances.length"
				class="up-list"
				[ngClass]="{ past: !upcomingToggledOn }"
				[style.height]="listHeight ? listHeight : ''">
				<div
					*ngFor="let instance of instances; let index = index"
					(click)="selectInstance(instance, index)"
					(mouseenter)="indexHovered = index"
					(mouseleave)="indexHovered = -1"
					[ngClass]="{ selected: instance.selected }"
					class="tw-cursor-pointer">
					<div class="tw-flex tw-justify-between tw-w-full">
						<div>
							<div class="time-header">{{ instance.start_time | date : 'EEEE, MMMM d' }}</div>
							<div class="time">
								{{ flexPeriod?.name }} <img class="ellispe-icon" [src]="'./assets/Ellipse (Gray500).svg' | resolveAsset" />
								{{ instance.start_time | date : 'h:mm a' }} - {{ instance.end_time | date : 'h:mm a' }}
							</div>
							<div class="num-attendees">
								{{ instance.current_num_attendees }}{{ activity?.max_attendees > 0 ? '/' + activity.max_attendees : '' }} students
							</div>
						</div>
						<div
							*ngIf="sessionsEditable && (indexHovered === index || instance.selected)"
							(click)="onOptionsClick($event, instance)"
							class="tw-cursor-pointer">
							<img
								class="tw-cursor-pointer tw-animate-fadein-200 tw-w-3.5 tw-ml-auto tw-mr-2 tw-p-3.25 tw-rounded-full"
								[ngClass]="{ 'hover:tw-bg-gray-150': !instance.selected, 'hover:tw-bg-navy-150': instance.selected }"
								src="./assets/Dots (Blue-Gray).svg"
								role="button" />
						</div>
					</div>
				</div>
			</div>
			<div
				*ngIf="loading && enableLoadingIndicator"
				class="tw-flex tw-justify-center tw-items-center loader-wrapper"
				[ngClass]="{ loader: sessionsEditable }">
				<mat-spinner></mat-spinner>
			</div>
			<div class="up-empty-state" *ngIf="!loading && instances.length === 0">No {{ upcomingToggledOn ? 'upcoming' : 'past' }} sessions.</div>
		</div>
	</ng-template>
</div>
<ng-template #deleteInstanceDialogBody>
	<div class="tw-text-gray-500">
		When you delete an activity session, it’s deleted for everyone. This action can’t be undone. Signups and data associated with the session will
		also be deleted.
	</div>
</ng-template>
