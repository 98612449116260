import { createAction, props } from '@ngrx/store';
import { IntroData, IntroDeviceTypes } from '../state';

const INTROS = 'INTROS';

type UpdateIntrosProps = { intros: IntroData; device: IntroDeviceTypes; version: string };

export const getIntros = createAction(`[${INTROS}] Get Intros`);
export const getIntrosSuccess = createAction(`[${INTROS}] Get Intros Success`, props<{ data: IntroData }>());
export const getIntrosFailure = createAction(`[${INTROS}] Get Intros Failure`, props<{ errorMessage: string }>());

export const updateIntros = createAction(`[${INTROS}] Update intros`, props<UpdateIntrosProps>());
export const updateIntrosSuccess = createAction(`[${INTROS}] Update intros Success`, props<{ data: IntroData }>());
export const updateIntrosFailure = createAction(`[${INTROS}] Update intros Failure`, props<{ errorMessage: string }>());

export const updateIntrosShareSmartpass = createAction(
	`[${INTROS}] Update intros Refer School`,
	props<{ intros: IntroData; device: IntroDeviceTypes; version: string }>()
);
export const updateIntrosShareSmartpassSuccess = createAction(`[${INTROS}] Update intros Refer School Success`, props<{ data: any }>());
export const updateIntrosShareSmartpassFailure = createAction(`[${INTROS}] Update intros Refer School Failure`, props<{ errorMessage: string }>());

export const updateIntrosStudentPassLimits = createAction(
	`[${INTROS}] Update intros Student Pass Limits`,
	props<{ intros: IntroData; device: IntroDeviceTypes; version: string }>()
);
export const updateIntrosStudentPassLimitsSuccess = createAction(`[${INTROS}] Update intros Student Pass Limits Success`, props<{ data: any }>());
export const updateIntrosStudentPassLimitsFailure = createAction(
	`[${INTROS}] Update intros Student Pass Limits Failure`,
	props<{ errorMessage: string }>()
);

export const updateIntrosFlexAdmin = createAction(`[${INTROS}] Update intros Flex Admin`, props<{ intros: any; device: string; version: string }>());

export const updateIntrosFlexAdminSuccess = createAction(`[${INTROS}] Update intros Flex Admin Success`, props<{ data: any }>());
export const updateIntrosFlexAdminFailure = createAction(`[${INTROS}] Update intros Flex Admin Failure`, props<{ errorMessage: string }>());

export const updateIntrosAdminSideBarNux = createAction(
	`[${INTROS}] Update Admin Sidebar Nux`,
	props<{ intros: IntroData; device: IntroDeviceTypes; version: string }>()
);
export const updateIntrosAdminSideBarNuxSuccess = createAction(`[${INTROS}] Update intros Flex Admin Success`, props<{ data: any }>());
export const updateIntrosAdminSideBarNuxFailure = createAction(`[${INTROS}] Update intros Flex Admin Failure`, props<{ errorMessage: string }>());

export const updateIntrosShowAsOriginRoomSuccess = createAction(`[${INTROS}] Update intros Show As Origin Room Success`, props<{ data: any }>());

export const updateIntrosAdminPassLimitsMessage = createAction(`[${INTROS}] Update intros Admin Pass Limits Message`, props<UpdateIntrosProps>());
export const updateIntrosAdminPassLimitsMessageSuccess = createAction(
	`[${INTROS}] Update intros Admin Pass Limits Message Success`,
	props<{ data: any }>()
);
export const updateIntrosAdminPassLimitsMessageFailure = createAction(
	`[${INTROS}] Update intros Admin Pass Limits Message Failure`,
	props<{ errorMessage: string }>()
);

// #combinedTeacherAdminNux
export const updateIntrosSeenCombinedTeacherAdminView = createAction(
	`[${INTROS}] Update intros Seen Combined Teacher Admin View`,
	props<UpdateIntrosProps>()
);

export const updateNewAdminHasSeenGetStarted = createAction(`[${INTROS}] Update updateNewAdminHasSeenGetStarted`, props<UpdateIntrosProps>());

export const updateNewAdminHasSeenGetStartedSuccess = createAction(`[${INTROS}] Update updateNewAdminHasSeenGetStarted`, props<{ data: any }>());

export const updateNewAdminHasSeenGetStartedFailure = createAction(
	`[${INTROS}] Update updateNewAdminHasSeenGetStarted Failure`,
	props<{ errorMessage: string }>()
);

export const updateIntrosSeenCombinedTeacherAdminViewSuccess = createAction(
	`[${INTROS}] Update intros Seen Combined Teacher Admin View Success`,
	props<{ data: any }>()
);

export const updateIntrosSeenCombinedTeacherAdminViewLocalReset = createAction(
	`[${INTROS}] Update intros Seen Combined Teacher Admin View Local Reset`,
	props<{ data: any }>()
);

export const updateIntrosSeenCombinedTeacherAdminViewFailure = createAction(
	`[${INTROS}] Update intros Seen Combined Teacher Admin View Failure`,
	props<{ errorMessage: string }>()
);

export const updateIntrosSeenNotificationAlertsSettingsAction = createAction(
	`[${INTROS}] Update intros Seen Notification Alerts Settings`,
	props<UpdateIntrosProps>()
);

export const updateIntrosSeenNotificationAlertsSettingsSuccess = createAction(
	`[${INTROS}] Update intros Seen Notification Alerts Settings Success`,
	props<{ data: any }>()
);

export const updateIntrosSeenNotificationAlertsSettingsFailure = createAction(
	`[${INTROS}] Update intros Seen Notification Alerts Settings Success`,
	props<{ errorMessage: string }>()
);
