<div class="ta-attendees">
	<div class="ta-detail-title">
		<img [src]="'./assets/Chevron Left (Navy).svg' | resolveAsset" alt="Back" (click)="handleBack()" />
		<div>
			<div>{{ selectedInstance?.start_time | date : 'EEEE, MMMM d' }}</div>
			<span>{{ selectedInstance?.start_time | date : 'h:mm a' }} - {{ selectedInstance?.end_time | date : 'h:mm a' }}</span>
		</div>
		<img
			*ngIf="showDeleteInstance"
			class="delete-button"
			[src]="'./assets/Dots (Blue-Gray).svg' | resolveAsset"
			alt="Remove"
			(click)="handleDeleteInstance($event, selectedInstance)" />
	</div>
	<ng-container *ngIf="activityService.attendees$ | async as attendees">
		<div class="ta-detail-list">
			<div>
				<span>
					{{ numAttendees }}
					<ng-container [ngPlural]="numAttendees">
						<ng-template ngPluralCase="=1"> student</ng-template>
						<ng-template ngPluralCase="other"> students</ng-template>
					</ng-container>
					attending
				</span>
				<div class="ta-add-btn" [ngClass]="addStudentDisabled || loading ? 'disabled' : ''" (click)="!addStudentDisabled ? toggleSearch() : null">
					<img [src]="'./assets/Plus (White).svg' | resolveAsset" alt="Add Student" *ngIf="!addStudentDisabled" />
					<span>{{ addStudentDisabled ? 'Activity Full' : 'Add Student' }}</span>
				</div>
			</div>
			<div *ngIf="!loading" class="ta-student-list">
				<div class="ta-student-item" *ngFor="let attendee of attendees">
					<sp-attendee-student
						*ngIf="attendee.user"
						[student]="attendee.user"
						[showAttendance]="showAttendance"
						[attendanceRecord]="getAttendanceForStudent(attendee.user, attendanceRecords)"
						(onCheckClicked)="removeSubject$.next(attendee.id)"
						(upsertAttendanceRecord)="upsertAttendanceRecord($event.state, $event.student, $event.attendanceRecord)">
					</sp-attendee-student>
				</div>
			</div>
			<mat-spinner *ngIf="loading"></mat-spinner>
		</div>
		<ng-container *ngIf="showAttendance && numUnmarkedRecords > 0">
			<div class="present-button" (click)="markAllAsPresent(attendees)">Mark all as Present</div>
		</ng-container>
	</ng-container>
</div>
<ng-template #addStudentsModalBody>
	<sp-student-instance-search [instance]="selectedInstance" [maxAttendance]="maxNumAttending"></sp-student-instance-search>
</ng-template>
